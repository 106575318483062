'use client';

import { useCallback, useEffect, useState, useContext } from 'react';
import { Droppable, DragDropContext } from '@hello-pangea/dnd';
import { useSnackbar } from 'src/components/snackbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import Scrollbar from 'src/components/scrollbar';
import EmptyContent from 'src/components/empty-content';

import KanbanColumn from '../kanban-column';
import KanbanColumnAdd from '../kanban-column-add';
import { KanbanColumnSkeleton } from '../kanban-skeleton';

import { useSocket } from 'src/context/SocketContext';

import { ToastContainer } from 'react-toastify';
import { getItem } from 'src/lib/cookies';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import NewLeadDialog from '../new-lead-dialog';
import { TokenContext } from '../../../context/TokenContext'
import TableFilters from '../../kyc/tableFilter';
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash';

const TableFilterSection = styled('div')(() => ({
  width: 'auto',
  padding: '16px 16px 16px 16px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

// ----------------------------------------------------------------------

export default function KanbanView({ setState, ticketType, board, setBoard, searchValue, products, filter }) {
  const socket = useSocket()
  const { enqueueSnackbar } = useSnackbar();
  const [boardLoading, setBoardLoading] = useState(true);
  const [leadinformation, setLeadinformation] = useState({ label: '', id: '', columnId: '', _id: '' });
  const confirm = useBoolean();
  const { decodedToken } = useContext(TokenContext);
  const [newAssignee, setNewAssignee] = useState({ avatarUrl: '', tokenUserName: '', id: '' })

  if (socket) {
    setState("connected")
  }

  const fetchTask = async (columnId, filterTickets) => {
    const count = filterTickets[columnId].fetchedCount;
    const taskIdsToFetch = filterTickets[columnId].taskIds.slice(count, count + 10);
    if (taskIdsToFetch.length === 0) {
      return
    }

    const payload = {
      columnId,
      ticketType,
      taskIds: taskIdsToFetch
    };

    return new Promise((resolve, reject) => {
      socket.emit("fetch_column_ticket", payload, (err, data) => {
        if (err) {
          enqueueSnackbar('Error in fetching data.', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: "error"
          });
          reject(err);
          return
        }
        setBoard((prevBoard) => {
          const newBoard = { ...prevBoard };
          newBoard.filterTickets[columnId].fetchedCount = count + 10
          const newTaks = { ...newBoard?.tasks, ...data };
          newBoard.tasks = newTaks;
          return newBoard;

        })
        resolve();
      });
    })

  }

  const fetchTicket = async (err, data) => {
    if (err) {
      enqueueSnackbar('Error in fetching data.', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: "error"
      });
      return
    }

    const { ordered, columns, filterTickets } = data;
    setBoard({ ordered, columns, tasks: {}, filterTickets });
    // setBoard(data)
    for (const element of ordered || []) {
      await fetchTask(element, filterTickets);
    }
    setBoardLoading(false)

  }

  useEffect(() => {
    setBoardLoading(true);

    const payload = {
      ticketType,
      searchValue: "",
      filter
    };

    const emitFetchColumns = debounce((searchValue) => {
      payload.searchValue = searchValue || "";
      if (products) {
        payload.products = products
      }
      if (socket) {
        socket.emit("fetch_columns", payload, fetchTicket);
      }
    }, 2000);

    emitFetchColumns(searchValue);

    return () => {
      emitFetchColumns.cancel();
    };
  }, [socket, ticketType, searchValue, products, filter]);


  const newTicket = (data) => {

    if (ticketType === "lead") {

      const label = data?.newTicket?.labels[0]
      const id = data?.newTicket?.id
      const _id = data?.newTicket?._id
      setLeadinformation({ label: label, id: id, _id })

      const { newTicket, columnId } = data;
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        // newBoard?.columns[columnId]?.taskIds.push(newTicket.id);
        newBoard.tasks[newTicket.id] = newTicket;
        return newBoard;
      });
      syncFilteredTickets(columnId);
    };
  }

  const newSupportTicket = (data) => {

    if (ticketType === "support") {
      const { newTicket, columnId } = data;

      const label = data?.newTicket?.labels?.[0]
      const id = data?.newTicket?.id
      const _id = data?.newTicket?._id
      setLeadinformation({ label: label, id: id, _id });
      enqueueSnackbar(`New support ticket received with name ${newTicket.name}`, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
      });

      setBoard((prevBoard) => {

        const newBoard = { ...prevBoard };
        // newBoard.columns[columnId].taskIds = [newTicket.id, ...newBoard.columns[columnId].taskIds]

        newBoard.tasks[newTicket.id] = newTicket;
        return newBoard;
      });
      syncFilteredTickets(columnId);
    }
  };


  const acceptNewTicket = (data) => {
    const label = data?.newTicket?.labels[0]
    const id = data?.newTicket?.id
    const _id = data?.newTicket?._id
    const columnId = data.columnId
    setLeadinformation({ label: label, id: id, columnId: columnId, _id: _id })

    if (Notification.permission === 'granted') {
      const userVisibility = (document.visibilityState === "visible");
      if (userVisibility) {
        return confirm.onTrue()
      }
    }

  };

  useEffect(() => {
    const hasIgnoredReload = localStorage.getItem("hasIgnoredReload");
    if (!decodedToken) {
      return window.location.reload(false);
    }

    if (decodedToken) {
      try {
        // const userDetails = jwtDecode(token);
        const { profileUrl = "", userName = "", email = "" } = decodedToken;
        setNewAssignee({
          avatarUrl: profileUrl,
          name: userName,
          id: email
        });
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("hasIgnoredReload");
        window.location.reload(false);
      }
    } else if (!hasIgnoredReload) {
      localStorage.setItem("hasIgnoredReload", "true");
    } else {
      localStorage.removeItem("hasIgnoredReload");
      window.location.reload();
    }
  }, [decodedToken]);

  const handleAccept = (data) => {

    if (ticketType === "lead") {
      const { id, _id, columnId } = data;
      const dataToCheck = { taskId: id, action: "accepted", flag: 1, _id, columnId };

      const showNotification = (message, variant) => {
        enqueueSnackbar(message, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant,
        });
      };

      const handleResponse = (response) => {
        if (response === "You can accept this lead!") {
          handleEmit({ newAssignee, ...dataToCheck }, "update_assigneeuser", (err) => {
            if (!err) {
              // socket.emit("fetch_columns", fetchTicket);
              syncFilteredTickets(columnId); // new lead always in 1-column as it is for new leads.

              showNotification('New lead has been accepted!', 'success');
            } else {
              showNotification('Error occurred during acceptance!', 'error');
            }
          });
        } else {
          showNotification('Someone accepted this lead!', 'warning');
        }
      };

      try {
        handleEmit({ taskId: id }, "check_assignee", (err, response) => {
          if (err) {
            console.error(err);
            showNotification('An unexpected error occurred!', 'error');
          } else {
            handleResponse(response);
          }
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('An unexpected error occurred!', {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'error',
        });
      }
    };
  }

  const handleReject = (data) => {
    const { id, columnId, pass } = data;
    const dataToReject = { taskId: id, action: pass || "rejected", flag: 1, newAssignee, columnId }
    try {
      handleEmit(dataToReject, "update_assigneeuser", (err, response) => {
        if (!err) {
          enqueueSnackbar('This leads passed!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Error occurred during rejection!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
          });
        }
      })
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An unexpected error occurred!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
      });
    }
  };

  const handleClose = (data) => {
    const { id, columnId } = data;
    const dataToReject = { taskId: id, action: "No response", flag: 1, newAssignee, columnId }
    try {
      handleEmit(dataToReject, "update_assigneeuser")
    } catch (error) {
      console.error(error);
    }
  };

  const moveTicket = async (data) => {
    const { destination, source, draggableId, ticketType: eventTicketType } = data;

    if (eventTicketType === ticketType) {
      syncFilteredTickets(source.droppableId);
      syncFilteredTickets(destination.droppableId);

      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard }
        const sourceColumnName = newBoard.columns[source.droppableId].name;
        const destinationColumnName = newBoard.columns[destination.droppableId].name
        if (newBoard.tasks[draggableId]) {
          const taskName = newBoard.tasks[draggableId].name;
          newBoard.columns[source.droppableId].taskIds.splice(source.index, 1);
          newBoard.columns[destination.droppableId].taskIds.splice(destination.index, 0, draggableId);
          newBoard.tasks[draggableId].status = newBoard.columns[destination.droppableId].name;
          enqueueSnackbar(`Task ${taskName} moved from ${sourceColumnName} to ${destinationColumnName} `, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }

        return newBoard;
      })
    }

  }

  const moveColumnhelper = (data) => {
    const { source, destination, draggableId, ticketType: eventTicketType } = data;

    if (eventTicketType === ticketType) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };

        newBoard.ordered.splice(source.index, 1);

        newBoard.ordered.splice(destination.index, 0, draggableId);
        return newBoard
      })
      enqueueSnackbar('Column has been moved!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

  }

  const syncColumn = (data) => {

    if (ticketType === "lead") {
      setBoard((prevBoard) => {
        const { columns } = data;
        const newBoard = { ...prevBoard };

        newBoard.columns = columns;
        return newBoard;
      });
    }


  }

  const syncSupportColumn = (data) => {
    if (ticketType === "support") {
      setBoard((prevBoard) => {
        const { columns } = data;
        const newBoard = { ...prevBoard };

        newBoard.columns = columns;
        return newBoard;
      });
    }


  }

  const newMailThread = (data) => {

    if (ticketType === "support") {

      const { taskId, mailThread, ticketName } = data;
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        newBoard.tasks[taskId].mailThread = mailThread;
        return newBoard;
      });

      enqueueSnackbar(`New mail thread received for task  ${ticketName}`, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'success',
      });


    }
  }

  const newComment = (data) => {
    const { taskId, comment } = data;
    if (board.tasks[taskId]) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        newBoard.tasks[taskId].comments.push(comment);
        enqueueSnackbar(`A new comment received for task ${newBoard.tasks[taskId].name}.`, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        return newBoard;
      })
    }

  }

  const updateTicket = (data) => {
    const { id, value, type, ticketType: eventTicketType } = data;
    console.log("board in update ticket", board);
    if (eventTicketType === ticketType) {
      if (board.tasks[id]) {
        setBoard((prevBoard) => {
          const newBoard = { ...prevBoard };
          newBoard.tasks[id][type] = value;
          enqueueSnackbar(`Task ${newBoard.tasks[id].name} has been updated!`, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
          return newBoard;
        });
      }

    }

  }

  const addNewColumn = (data) => {
    const { newColumn, ticketType: eventTicketType, callByPanel } = data;

    if (eventTicketType === ticketType || callByPanel) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        newBoard.columns[newColumn.id] = newColumn;
        newBoard.ordered.push(newColumn.id);
        newBoard.filterTickets[newColumn.id] = { taskIds: [], fetchedCount: 0 }
        return newBoard;
      })
      enqueueSnackbar('A new Column is Created!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

  }

  const updateColumn = (data) => {
    const { id, newName, ticketType: eventTicketType } = data;
    if (eventTicketType === ticketType) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };
        newBoard.columns[id].name = newName;
        return newBoard;
      })
      enqueueSnackbar('Column has been updated!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

  }

  const deleteColumn = (data) => {
    const { id, ticketType: eventTicketType } = data;

    if (eventTicketType === ticketType) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard }
        const columnName = newBoard.columns[id].name;
        delete newBoard.columns[id];
        const index = newBoard.ordered.indexOf(id);
        newBoard.ordered.splice(index, 1);
        enqueueSnackbar(`Column ${columnName}  has been deleted.`, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        return newBoard;
      })
    }

  }


  const deleteTask = (data) => {
    const { taskId, columnId, ticketType: eventTicketType } = data;

    if (eventTicketType === ticketType) {
      setBoard((prevBoard) => {
        const newBoard = { ...prevBoard };

        const index = newBoard.columns[columnId].taskIds.indexOf(taskId);
        newBoard.columns[columnId].taskIds.splice(index, 1);
        if (newBoard.tasks[taskId]) {

          const taskName = newBoard.tasks[taskId].name;

          delete newBoard.tasks[taskId];
          enqueueSnackbar(`Task ${taskName} has been deleted.`, {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }

        return newBoard;
      })
      syncFilteredTickets(columnId);
    }

  }

  const syncFilteredTickets = useCallback((columnId) => {
    try {

      const payload = { columnId, ticketType };
      if (searchValue) {
        payload.searchValue = searchValue
      }
      if (products) {
        payload.products = products;
      }
      if (filter) {
        payload.filter = filter;
      }
      socket.emit("sync_filtered_tickets", payload, (err, data) => {
        if (err) {
          console.log(err);
        }
        else {
          setBoard((prevBoard) => {
            const newBoard = { ...prevBoard };
            newBoard.filterTickets[columnId].taskIds = data;
            return newBoard;
          })

          let taskIdsToFetch = []


          setBoard((prevBoard) => {
            const newBoard = { ...prevBoard };
            const { filterTickets, tasks } = newBoard;

            let count = filterTickets[columnId].fetchedCount
            taskIdsToFetch = filterTickets[columnId].taskIds.slice(0, count);
            taskIdsToFetch = taskIdsToFetch.filter((taskId) => !tasks[taskId])
            if (taskIdsToFetch.length === 0) {
              return newBoard
            }
            const payload = {
              columnId,
              ticketType,
              taskIds: taskIdsToFetch
            };

            socket.emit("fetch_column_ticket", payload, (err, data) => {
              if (err) {
                enqueueSnackbar('Error in fetching data.', {
                  anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  variant: "error"
                });
                return
              }
              else {

                // const newTaks = { ...newBoard?.tasks, ...data };
                // newBoard.tasks = newTaks;
                // console.log("🚀  - > Nitesh ~ setBoard ~ newBoard:", newBoard)

                setBoard((currentBoard) => {
                  const updatedTasks = { ...currentBoard.tasks, ...data };
                  return {
                    ...currentBoard,
                    tasks: updatedTasks,
                  };
                });

              }

            }
            )

            return newBoard;
          })

        }

      })

    } catch (error) {

    }
  }, [board, searchValue])

  const updateAssignee = (data) => {
    const { newAssignee: { avatarUrl, name, id }, taskId, action, task, unAssigned, assigned } = data;

    // if (!unAssigned) {
    //   setBoard((prevBoard) => {
    //     const newBoard = { ...prevBoard };
    //     newBoard.tasks[taskId] = task;
    //     return newBoard;
    //   })
    //   return
    // }

    if (action === "removed" && unAssigned) {
      if (board.tasks[taskId]) {
        setBoard((prevBoard) => {
          const newBoard = { ...prevBoard };
          delete newBoard.tasks[taskId];
          return newBoard;
        })
      }
      const { columnId } = task;
      syncFilteredTickets(columnId);

    }
    else {
      if (board.tasks[taskId]) {
        setBoard((prevBoard) => {
          const newBoard = { ...prevBoard };
          newBoard.tasks[taskId] = task;
          return newBoard;
        })
      }
      if (assigned) {
        const { columnId } = task;

        syncFilteredTickets(columnId);
      }
    }

  }


  useEffect(() => {
    if (socket) {
      // socket.on(ticketType === "support" ? "new_support_ticket" : "new_ticket", newTicket);
      socket.on("new_support_ticket", newSupportTicket);
      socket.on("new_ticket", newTicket);
      socket.on("new_mail_thread", newMailThread);

      // socket.on("acceptnew_ticket", acceptNewTicket)

      socket.on("move_ticket", moveTicket);

      socket.on("sync_column", syncColumn);
      socket.on("sync_support_column", syncSupportColumn);

      socket.on("new_comment", newComment);

      socket.on("update_ticket", updateTicket);

      socket.on("update_assignee", updateAssignee)

      socket.on("new_column", addNewColumn);

      socket.on("update_column", updateColumn);

      socket.on("delete_column", deleteColumn);

      socket.on("delete_task", deleteTask);

      socket.on("move_column", moveColumnhelper);
    }
    if (socket) {
      return () => {
        // socket.off(ticketType === "support" ? "new_support_ticket" : "new_ticket", newTicket);

        socket.off("new_support_ticket", newSupportTicket);
        socket.off("new_ticket", newTicket)
        socket.off("new_mail_thread", newMailThread);


        // socket.off("acceptnew_ticket", acceptNewTicket);

        socket.off("move_ticket", moveTicket);

        socket.off("sync_column", syncColumn);
        socket.off("sync_support_column", syncSupportColumn);


        socket.off(ticketType === "support" ? "new_chat" : "new_comment", newComment);

        socket.off("update_ticket", updateTicket);

        socket.off("update_assignee", updateAssignee)

        socket.off("new_column", addNewColumn);

        socket.off("update_column", updateColumn);

        socket.off("delete_column", deleteColumn);

        socket.off("delete_task", deleteTask);

        socket.off("move_column", moveColumnhelper);
      }
    }
  }, [socket, ticketType, searchValue, board, products])

  const handleEmit = (data, type, func) => {
    if (!socket) {
      func(true, "Please connect to server.")
      return
    }
    data.ticketType = ticketType;
    switch (type) {
      case "move_ticket":
        socket.emit("move_ticket", data, func);
        break;
      case "update_assignee":
        socket.emit("update_assignee", data, func);
        break;
      case "update_assigneeuser":
        socket.emit("update_assigneeuser", data, func);
        break;
      // case "check_assignee":
      //   socket.emit("check_assignee", data, func);
      //   break;
      case "ticket":
        socket.emit("update_ticket", data, func);
        break;
      case "comment":
        socket.emit(ticketType === "support" ? "support_chat" : "add_comment", data, func);
        break;
      case "sendReply":
        socket.emit("send_reply", data, func)
        break;
      case "addColumn":
        socket.emit("add_column", data, func);
        break;
      case "updateColumn":
        socket.emit("update_column", data, func);
        break;
      case "deleteColumn":
        socket.emit("delete_column", data, func);
        break;
      case "deleteTask":
        socket.emit("delete_task", data, func);
        break;
      case "moveColumn":
        socket.emit("move_column", data, func);
        break;
      case "reject_leads":
        socket.emit("reject_leads", data, func);
      case "activeUsers":
        socket.emit("active_users", data, func);
        break;
      default:
        func(true);
    }
  }


  const boardEmpty = false;

  const moveColumn = async (newOrder) => {
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard }
      newBoard.ordered = newOrder;
      return newBoard;
    })
  }

  const moveTask = (newTaskOrder) => {
    setBoard((prevBoard) => {
      const newBoard = { ...prevBoard }
      newBoard.columns = newTaskOrder
      return newBoard
    })

  }

  const onDragEnd = useCallback(
    async ({ destination, source, draggableId, type }) => {
      const destinationCopy = { ...destination };
      const sourceCopy = { ...source };
      if (!socket.connected) {
        enqueueSnackbar("Please connect to server.", {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: "error"
        });
        return
      }
      try {
        if (!destination) {
          return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
          return;
        }

        // Moving column
        if (type === 'COLUMN') {

          let newBoard;
          setBoard((prevBoard) => {
            newBoard = { ...prevBoard }
            return prevBoard;
          })

          const newOrdered = [...newBoard.ordered];

          newOrdered.splice(source.index, 1);

          newOrdered.splice(destination.index, 0, draggableId);

          moveColumn(newOrdered);

          const data = { source, destination, draggableId };
          handleEmit(data, "moveColumn", (err, data) => {
            if (err) {
              enqueueSnackbar(data, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: "error"
              });
              setBoard(newBoard);
            }
            else {
              enqueueSnackbar('Column moved successfully!', {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              });
            }
          })

          return;
        }

        let newBoard;
        let sourceColumn;
        let destinationColumn

        setBoard((prevalue) => {
          newBoard = { ...prevalue };

          sourceColumn = newBoard?.columns[source.droppableId];
          destinationColumn = newBoard?.columns[destination.droppableId];

          return prevalue;
        })

        // Moving task to same list
        if (sourceColumn.id === destinationColumn.id) {

          // if moving up to down
          if (source.index < destination.index) {

            let cd = 0
            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (sourceColumn.taskIds[i] === draggableId) {
                source.index = i;
                break;
              }
            }

            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (newBoard.tasks[sourceColumn.taskIds[i]]) {
                cd++;
              }

              if (cd === (destination.index + 1)) {
                destination.index = i
                break
              }
            }

          }
          // when moving down to up
          else {
            let cd = 0
            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (sourceColumn.taskIds[i] === draggableId) {
                source.index = i;
                break;
              }
            }

            for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
              if (newBoard.tasks[sourceColumn.taskIds[i]]) {

                cd++;
              }

              if (cd === (destination.index)) {
                destination.index = i + 1
                break
              }
            }

          }

          const newTaskIds = [...sourceColumn.taskIds];

          newTaskIds.splice(source.index, 1);

          newTaskIds.splice(destination.index, 0, draggableId);

          const finalColumn = {
            ...newBoard?.columns,
            [sourceColumn.id]: {
              ...sourceColumn,
              taskIds: newTaskIds,
            },
          }


          moveTask(finalColumn);
          console.log(source, sourceCopy, "dfnb");
          console.log(destination, destinationCopy, "dfgfgn")

          setBoard((prevBoard) => {
            const newBoard = { ...prevBoard };
            newBoard.filterTickets[sourceCopy.droppableId].taskIds.splice(sourceCopy.index, 1);
            newBoard.filterTickets[destinationCopy.droppableId].taskIds.splice(destinationCopy.index, 0, draggableId);
            return newBoard;
          })

          const agentEmail = newBoard.tasks[draggableId].assignedAdmin;
          const data = { destination, source, draggableId, agentEmail }

          handleEmit(data, "move_ticket", async (err, data) => {
            if (err) {
              setBoard(newBoard);
              enqueueSnackbar(data, {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: "error"
              });
              return
            }
            else {
              console.log("start")
              syncFilteredTickets(source.droppableId);
              syncFilteredTickets(destination.droppableId)
              enqueueSnackbar('Task moved successfully!', {
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              });
            }
          })


          return;
        }

        let cd = 0
        for (let i = 0; i < sourceColumn.taskIds?.length; i++) {
          if (sourceColumn.taskIds[i] === draggableId) {
            source.index = i;
            break;

          }
        }

        for (let i = 0; i < destinationColumn.taskIds?.length; i++) {
          if (newBoard.tasks[destinationColumn.taskIds[i]]) {

            cd++;
          }

          if (cd === (destination.index)) {
            destination.index = i + 1
            break;
          }
        }

        // Moving task to different list
        const sourceTaskIds = [...sourceColumn.taskIds];

        const destinationTaskIds = [...destinationColumn.taskIds];

        // Remove from source
        sourceTaskIds.splice(source.index, 1);

        // Insert into destination
        destinationTaskIds.splice(destination.index, 0, draggableId);

        let finalColumn;


        finalColumn = {
          ...newBoard?.columns,
          [sourceColumn.id]: {
            ...sourceColumn,
            taskIds: sourceTaskIds,
          },
          [destinationColumn.id]: {
            ...destinationColumn,
            taskIds: destinationTaskIds,
          },
        }
        setBoard((prevBoard) => {
          const newBoard = { ...prevBoard };
          newBoard.tasks[draggableId].status = newBoard.columns[destination.droppableId].name;

          return prevBoard
        })

        moveTask(finalColumn)

        setBoard((prevBoard) => {
          const newBoard = { ...prevBoard };
          newBoard.filterTickets[sourceCopy.droppableId].taskIds.splice(sourceCopy.index, 1);
          newBoard.filterTickets[destinationCopy.droppableId].taskIds.splice(destinationCopy.index, 0, draggableId);
          return newBoard;
        })


        const agentEmail = newBoard.tasks[draggableId].assignedAdmin;
        const data = { destination, source, draggableId, agentEmail }

        handleEmit(data, "move_ticket", (err, data) => {
          if (err) {
            enqueueSnackbar(data, {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: "error"
            });
            setBoard(newBoard);
            return
          }
          else {
            enqueueSnackbar('Task moved successfully!', {
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              variant: "success"
            });
            syncFilteredTickets(source.droppableId);
            syncFilteredTickets(destination.droppableId)
          }
        })
      } catch (error) {
        console.error(error);
      }
    },
    [board?.columns, board?.ordered]
  );

  const renderSkeleton = (
    <Stack direction="row" alignItems="flex-start" spacing={3}>
      {[...Array(4)].map((_, index) => (
        <KanbanColumnSkeleton key={index} index={index} />
      ))}
    </Stack>
  );


  return (
    <>
      <ToastContainer style={{ marginTop: "40px" }} />
      <Container
        maxWidth={false}
        sx={{
          height: 1,
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
        }}
      >

        {boardLoading && renderSkeleton}

        {boardEmpty && (
          <EmptyContent
            filled
            title="No Data"
            sx={{
              py: 10,
              maxHeight: { md: 480 },
            }}
          />
        )}
        {!boardLoading && !!board?.ordered?.length && (

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="board" type="COLUMN" direction="horizontal">
              {(provided) => (
                <Scrollbar
                  sx={{
                    height: 1,
                    minHeight: {
                      xs: '80vh',
                      md: 'unset',
                    },
                    '&.simplebar-placeholder': {
                      width: '0px',
                      height: '0px'
                    }
                  }}
                >
                  <Stack
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    spacing={3}
                    direction="row"
                    alignItems="flex-start"
                    sx={{
                      p: 0.25,
                      height: 1,
                    }}

                  >
                    {board?.ordered.map((columnId, index) => (
                      <KanbanColumn
                        index={index}
                        key={columnId}
                        column={board?.columns[columnId]}
                        tasks={board?.tasks}
                        board={board}
                        setBoard={setBoard}
                        handleEmit={handleEmit}
                        ticketType={ticketType}
                        fetchTask={fetchTask}
                        syncFilteredTickets={syncFilteredTickets}
                      />
                    ))}

                    {provided.placeholder}

                    <KanbanColumnAdd addNewColumn={addNewColumn} handleEmit={handleEmit} />
                  </Stack>
                </Scrollbar>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Container>

      <NewLeadDialog
        open={confirm.value}
        setOpen={confirm.onFalse}
        handleAccept={handleAccept}
        handleReject={handleReject}
        handleClose={handleClose}
        details={leadinformation}
      />
    </>
  );
}

