import { TextField, Box, Typography, Stack, Select, MenuItem, InputLabel, FormControl, Button, Tooltip, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { axiosVBGetCall, axiosVBPatchCall, axiosVBPostCall } from '../../../services/vbApiCall';
import { axiosGetCall, axiosPostCall, axiosPatchCall } from 'src/services/apisCall';

import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import Logger from '../../../lib/Logger';
import { copyToClipBoard } from '../../../utils/common';
import Iconify from '../../../components/iconify';
import { axiosVBGetCall } from 'src/services/vbApiCall';
import { set } from 'lodash';
const logger = new Logger("asetting")



const BasicDatePicker = ({ value, onChange, dateError, resetError, label }) => {
    const [/* open */, setOpen] = useState(false);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <TimePicker views={['hours']}
                label={label}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                slotProps={{
                    textField: {
                        size: 'small',
                        error: Boolean(dateError),
                        helperText: Boolean(dateError) && dateError,
                        onClick: () => resetError()
                    },
                    actionBar: {
                        actions: ['today', 'clear'],
                    },
                }}
                ampm={true}
                // sx={{ width: "100%", margin: "8px" }}
                value={value} onChange={(newValue) => onChange(newValue)}

            />
        </LocalizationProvider>
    )
}


const requestList = [
    { label: "Self", value: "U" },
    { label: "Downline", value: "D" },
    { label: "Hierarchy", value: "H" }
]

const dndStatusList = [
    { label: "On", value: "1" },
    { label: "Off", value: "0" }
]



const BroadcastSettings = ({ user }) => {

    const inputSx = {
        color: "grey.900",
        borderColor: 'grey.700',
        borderRadius: "6px",
    };

    const [anouncementUploadLimit, setAnouncementUploadLimit] = useState(0);
    const [callLimit, setCallLimit] = useState(0);
    const [answerPercent, setAnswerPercent] = useState(0);
    const [callWindowRequestFor, setCallWindowRequestFor] = useState("U");
    const [callLimitRequestFor, setCallLimitRequestFor] = useState("U");
    const [answerPercentRequestFor, setAnswerPercentRequestFor] = useState("U");
    const [uploadLimitRequestFor, setUpdateLimitRequestFor] = useState("U");
    const [dndStatusRequestFor, setDndStatusRequestFor] = useState("U");
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [dndStatus, setDndStatus] = useState("0");
    const [startingRange, setStartingRange] = useState(0);
    const [endingRange, setEndingRange] = useState(0);
    const [startCuttingPercentage, setStartCuttingPercentage] = useState(0);
    const [endCuttingPercentage, setEndCuttingPercentage] = useState(0);
    const [cuttingRequestFor, setCuttingRequestFor] = useState("U");
    const [mockAnswer, setMockAnswer] = useState(0);
    
    const [userToken, setUserToken] = useState("");
    const [callWindowloader, setCallWindowLoader] = useState(false);
    const [answerPercentLoader, setAnswerPercentLoader] = useState(false);
    const [callLimitLoader, setCallLimitLoader] = useState(false);
    const [announcementLimitLoader, setAnnouncementLoader] = useState(false);
    const [dndLoader, setDndLoader] = useState(false);
    const [load, setLoad] = useState(false);
    const [cuttingLoader, setCuttingLoader] = useState(false);
    

    const updateCallLimit = async () => {
        try {
            setCallLimitLoader(true);
            const payload = {
                userId: user?.voiceInfo?.userId,
                action: "updateCallLimits",
                callLimit,
                requestFor: "U",
            }
            if (user?.voiceInfo?.userType === "reseller") {
                payload.requestFor = callLimitRequestFor;
            }
            await axiosPatchCall("updateProfileSetting", payload);
            setCallLimitLoader(false);

            renderSuccessMessage("Success");
        } catch (error) {
            logger.error(error)
            setCallLimitLoader(false);
            renderErrorMessage(error.message);
        }
    }

    const updateAnnouncementUploadLimit = async () => {
        try {
            setAnnouncementLoader(true);
            const payload = {
                userId: user?.voiceInfo?.userId,
                action: "updateAnnouncementUploadLimit",
                announcementLimit: parseInt(anouncementUploadLimit),
                requestFor: "U",
            }
            if (user?.voiceInfo?.userType === "reseller") {
                payload.requestFor = uploadLimitRequestFor;
            }
            await axiosPatchCall("updateProfileSetting", payload);
            setAnnouncementLoader(false);
            renderSuccessMessage("Success");
        } catch (error) {
            logger.error(error)
            setAnnouncementLoader(false);
            renderErrorMessage(error.message)
        }
    }

    const updateDndStatus = async () => {
        try {
            setDndLoader(true);
            const payload = {
                userId: user?.voiceInfo?.userId,
                DNDFilterStatus: dndStatus,
                action: "changeUserDNDFilterStatus",
                requestFor: "U",
            };

            if (user?.voiceInfo?.userType === "reseller") {
                payload.requestFor = dndStatusRequestFor;
            }
            await axiosPatchCall("updateProfileSetting", payload);
            setDndLoader(false);
            renderSuccessMessage("Success");
        } catch (error) {
            logger.error(error);
            setDndLoader(false);
            renderErrorMessage(error.message);
        }
    }

    const updateACallWindow = async () => {
        try {
            if (!startTime) {
                return setStartDateError("Start date is required");
            }
            if (!endTime) {
                return setEndDateError("End date is required")
            }
            const startHour = dayjs(startTime).hour();
            const endHour = dayjs(endTime).hour();

            if (startHour > endHour) {
                return setEndDateError("End date must be greater than the Start date")
            }

            if (startHour === endHour && startHour !== 0) {
                return setEndDateError("Start Time and End Time not be same")
            }
            const payload = {
                userId: user?.voiceInfo?.userId,
                action: "updateCallWindow",
                callWindowStart: startHour,
                callWindowEnd: endHour,
                requestFor: "U",
            }
            if (user?.voiceInfo?.userType === "reseller") {
                payload.requestFor = callWindowRequestFor;
            }
            setCallWindowLoader(true);
            await axiosPatchCall("updateProfileSetting", payload);
            setCallWindowLoader(false);
            renderSuccessMessage("Success");
        }
        catch (error) {
            logger.error(error);
            setCallWindowLoader(false);
            renderErrorMessage(error.message)
        }
    }

    const updateCuttingPercentage = async () => {
        try {

            if (startCuttingPercentage > endCuttingPercentage) {
                return renderErrorMessage("Start Cutting Percentage must be less than End Cutting Percentage")
            }

            if (startCuttingPercentage === endCuttingPercentage && startCuttingPercentage !== 0) {
                return renderErrorMessage("Start Cutting Percentage and End Cutting Percentage not be same")
            }

            const payload = {
                userId: user?.voiceInfo?.userId,
                action: "setCutting",
                startingRange: parseInt(startingRange),
                endingRange: parseInt(endingRange),
                startCuttingPercentage: parseInt(startCuttingPercentage),
                endCuttingPercentage: parseInt(endCuttingPercentage),
                requestFor: cuttingRequestFor,
            }
            if (user?.voiceInfo?.userType === "reseller") {
                payload.requestFor = callWindowRequestFor;
            }

            if (user?.voiceInfo?.userType === "reseller") {
                payload.mockToAnswer = mockAnswer;
            }

            setCuttingLoader(true);
            await axiosPatchCall("updateProfileSetting", payload);
            setCuttingLoader(false);
            renderSuccessMessage("Success");

        } catch (error) {
            logger.error(error);
            setCuttingLoader(false);
            renderErrorMessage(error.message)
        }
    }

    const updateAnsweringPercentage = async () => {
        try {
            const payload = {
                userId: user?.voiceInfo?.userId,
                answeringPercentage: answerPercent,
                action: "setAnswering",
                requestFor: "U",
            };

            if (user?.voiceInfo?.userType === "reseller") {
                payload.requestFor = answerPercentRequestFor;
            }
            await axiosPatchCall("updateProfileSetting", payload);
            setDndLoader(false);
            renderSuccessMessage("Success");

        } catch (error) {
            logger.error(error);
            setAnswerPercentLoader(false);
            renderErrorMessage(error.message);
        }
    }

    const handleReset = async (type) => {
        let payload = {
            userId: user?.voiceInfo?.userId,
            action: "resetSetting",
            resetActionType: "",
            requestFor: "U"
        }
        if (type === "callLimit") {
            payload.resetActionType = "updateCallLimits";
            payload.requestFor = callLimitRequestFor;
        }

        if (type === "announcementLimit") {
            payload.resetActionType = "updateAnnouncementUploadLimit";
            payload.requestFor = uploadLimitRequestFor;
        }

        if (type === "callWindow") {
            payload.resetActionType = "updateCallWindow";
            payload.requestFor = callWindowRequestFor;
        }

        if (type === "dndStatus") {
            payload.resetActionType = "changeUserDNDFilterStatus";
            payload.requestFor = dndStatusRequestFor;
        }

        if (type === "answerPercentage") {
            payload.resetActionType = "setAnswering";
            payload.requestFor = answerPercentRequestFor;
        }

        if (type === "cutting") {
            payload.resetActionType = "setCutting";
            payload.requestFor = cuttingRequestFor;
        }
        try {
            await axiosPatchCall("updateProfileSetting", payload)
            setLoad((prev) => !prev);
        } catch (error) {
            logger.error(error);
            setAnswerPercentLoader(false);
            renderErrorMessage(error.message);
        }
    }

    const handleSubmit = async (type) => {
        if (type === "callLimit") {
            await updateCallLimit();
        }

        if (type === "announcementLimit") {
            await updateAnnouncementUploadLimit();
        }

        if (type === "callWindow") {
            await updateACallWindow();
        }

        if (type === "dndStatus") {
            await updateDndStatus();
        }

        if (type === "answerPercentage") {
            await updateAnsweringPercentage();
        }

        if (type === "cutting") {
            await updateCuttingPercentage();
        }

    }

    const resetStartTimeError = useMemo(() => {
        return () => {
            setStartDateError("");
        };
        // eslint-disable-next-line
    }, [startTime]);

    const resetEndTimeError = useMemo(() => {
        return () => {
            setEndDateError("")
        }
        // eslint-disable-next-line
    }, [endTime])

    const fetchSettingDetails = async () => {
        try {
            const params = { requestUserId: user?.voiceInfo?.userId, requestName: "all" }
            const { data = {} } = await axiosGetCall("getUserSettingsInfo", { ...params });
            const {
                data: { answerPercentage = 0 },
            } = await axiosGetCall("getUserSettingsInfo", {
                requestName: "answerPer",
                requestUserId: user?.voiceInfo?.userId,
            });
            setAnswerPercent(answerPercentage)
            // setDndStatus(`${answerPer}`)
            const { data: { startingRange, endingRange, startCuttingPercentage, endCuttingPercentage, mockToAnswer } } = await axiosGetCall("getUserSettingsInfo", {
                requestName: "setCutting",
                requestUserId: user?.voiceInfo?.userId,
            });
            setStartCuttingPercentage(startCuttingPercentage);
            setStartingRange(startingRange);
            setEndingRange(endingRange);
            setEndCuttingPercentage(endCuttingPercentage);
            setMockAnswer(mockToAnswer)
            const { dailyCallLimit = 0, callWindowStartTime = 0, callWindowEndTime = 0, announcementUploadLimit = 0, DNDFilterStatus } = data;
            setDndStatus(DNDFilterStatus)
            const currentDate = dayjs();
            const startTime = currentDate.hour(callWindowStartTime).minute(0).second(0).millisecond(0);
            const endTime = currentDate.hour(callWindowEndTime).minute(0).second(0).millisecond(0);

            setCallLimit(parseInt(dailyCallLimit || 0));
            setAnouncementUploadLimit(parseInt(announcementUploadLimit || 0));

            setStartTime(startTime);
            setEndTime(endTime);
        } catch (error) {
            logger.error(error);
        }
    }

    useEffect(() => {
        fetchSettingDetails();
        // eslint-disable-next-line
    }, [load])
    

    return (
        <>
        
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "10px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    mb: "15px",

                }}
            >
                <Typography>Call Window</Typography>

                    <Box sx={{ border: "0.5px dashed",
                    borderColor: "gray.400",
                    borderRadius:"7px",
                    padding: "12px",
                    paddingTop: "14px"}}>

                {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={1}> */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px",

                      
               }}
                >
                    <BasicDatePicker
                        label={"Start Time"}
                        value={startTime}
                        onChange={setStartTime}
                        dateError={startDateError}
                        resetError={resetStartTimeError}
                    />
                    <BasicDatePicker
                        label={"End Time"}
                        value={endTime}
                        onChange={setEndTime}
                        dateError={endDateError}
                        resetError={resetEndTimeError}
                    />
                    {user?.voiceInfo?.userType !== "reseller" && (<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"row"} gap={1}>
                            <IconButton onClick={() => handleReset("callWindow")}>
                                <Iconify icon="ix:reset" />
                            </IconButton>
                            <LoadingButton
                                loading={callWindowloader}
                                variant="contained"
                                sx={{ width: "100px" }}
                                onClick={() => handleSubmit("callWindow")}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Box>)}
                </Box>
                {/* 
          </Stack> */}
                <Stack width={"100%"} direction={"row"} alignItems={"center"} >
                    {user?.voiceInfo?.userType === "reseller" && (
                        <Stack
                            gap={2}
                            direction={"row"}
                            width={"100%"}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                            }}
                        >
                            {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
                            <FormControl fullWidth>
                                <InputLabel id="request-select">Request For</InputLabel>
                                <Select
                                    labelId="request-select"
                                    id="request-select"
                                    fullWidth
                                    value={callWindowRequestFor || ""}
                                    onChange={(e) => setCallWindowRequestFor(e?.target?.value)}
                                    label="Request For"
                                    size="small"
                                    displayEmpty
                                    sx={{
                                        width: "50%",
                                    }}
                                >
                                    {requestList.map(({ label, value }) => (
                                        <MenuItem key={label} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        
                    )}
                    
                    {user?.voiceInfo?.userType === "reseller" && (
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Stack direction={"row"} gap={1}>
                                <IconButton onClick={() => handleReset("callWindow")}>
                                    <Iconify icon="ix:reset" />
                                </IconButton>
                                <LoadingButton
                                    loading={callWindowloader}
                                    variant="contained"
                                    sx={{ width: "100px" }}
                                    onClick={() => handleSubmit("callWindow")}
                                >
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </Box>
                    )}
                </Stack>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    gap: "6px",
                    mb: "15px",
                    border: "0.5px dashed",
                    borderColor: "gray.400",
                    borderRadius:"7px",
                    padding: "12px",
                    paddingTop: "14px"
                }}
            >
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Enter Call Limit"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={callLimit}
                    onChange={(e) => setCallLimit(e.target.value)}
                    size="small"
                />
                {user?.voiceInfo?.userType === "reseller" && (
                    <Stack
                        gap={2}
                        direction={"row"}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
                        <FormControl fullWidth>
                            <InputLabel id="request-select">Request For</InputLabel>
                            <Select
                                labelId="request-select"
                                id="request-select"
                                value={callLimitRequestFor || ""}
                                onChange={(e) => setCallLimitRequestFor(e?.target?.value)}
                                label="Request For"
                                size="small"
                                displayEmpty
                            >
                                {requestList.map(({ label, value }) => (
                                    <MenuItem key={label} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction={"row"} gap={1}>
                        <IconButton onClick={() => handleReset("callLimit")}>
                            <Iconify icon="ix:reset" />
                        </IconButton>
                        <LoadingButton
                            loading={callLimitLoader}
                            variant="contained"
                            sx={{ width: "100px" }}
                            onClick={() => handleSubmit("callLimit")}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>

                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    // flexDirection: 'column',
                    gap: "6px",
                    mb: "15px",
                    border: "0.5px dashed",
                    borderColor: "gray.400",
                    borderRadius:"7px",
                    padding: "12px",
                    paddingTop: "14px"
                }}
            >
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Announcement Upload Limit"
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={anouncementUploadLimit}
                    onChange={(e) => setAnouncementUploadLimit(e.target.value)}
                    size="small"
                />
                {user?.voiceInfo?.userType === "reseller" && (
                    <Stack
                        gap={2}
                        direction={"row"}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
                        <FormControl fullWidth>
                            <InputLabel id="request-select">Request For</InputLabel>
                            <Select
                                labelId="request-select"
                                id="request-select"
                                value={uploadLimitRequestFor || ""}
                                onChange={(e) => setUpdateLimitRequestFor(e?.target?.value)}
                                label="Request For"
                                size="small"
                                displayEmpty
                            >
                                {requestList.map(({ label, value }) => (
                                    <MenuItem key={label} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction={"row"} gap={1}>
                        <IconButton onClick={() => handleReset("announcementLimit")}>
                            <Iconify icon="ix:reset" />
                        </IconButton>
                        <LoadingButton
                            loading={announcementLimitLoader}
                            variant="contained"
                            sx={{ width: "100px" }}
                            onClick={() => handleSubmit("announcementLimit")}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    gap: "6px",
                    mb: "15px",
                    border: "0.5px dashed",
                    borderColor: "gray.400",
                    borderRadius:"7px",
                    padding: "12px",
                    paddingTop: "14px"
                }}
            >
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Answer Percentage"
                    type='number'
                    variant="outlined"
                    InputProps={{
                        sx: inputSx,
                    }}
                    value={answerPercent}
                    onChange={(e) => setAnswerPercent(e.target.value)}
                    size="small"
                />
                {user?.voiceInfo?.userType === "reseller" && (
                    <Stack
                        gap={2}
                        direction={"row"}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
                        <FormControl fullWidth>
                            <InputLabel id="request-select">Request For</InputLabel>
                            <Select
                                labelId="request-select"
                                id="request-select"
                                value={callLimitRequestFor || ""}
                                onChange={(e) => setAnswerPercentRequestFor(e?.target?.value)}
                                label="Request For"
                                size="small"
                                displayEmpty
                            >
                                {requestList.map(({ label, value }) => (
                                    <MenuItem key={label} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction={"row"} gap={1}>
                        <IconButton onClick={() => handleReset("answerPercentage")}>
                            <Iconify icon="ix:reset" />
                        </IconButton>
                        <LoadingButton
                            loading={answerPercentLoader}
                            variant="contained"
                            sx={{ width: "100px" }}
                            onClick={() => handleSubmit("answerPercentage")}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    gap: "6px",
                    mb: "15px",
                    border: "0.5px dashed",
                    borderColor: "gray.400",
                    borderRadius:"7px",
                    padding: "12px",
                    paddingTop: "14px"
                }}
            >
                <FormControl fullWidth>
                    <InputLabel id="request-select">Dnd Status</InputLabel>
                    <Select
                        labelId="request-select"
                        id="request-select"
                        value={dndStatus || ""}
                        onChange={(e) => setDndStatus(e?.target?.value)}
                        label="Dnd Status"
                        size="small"
                        displayEmpty
                    >
                        {dndStatusList.map(({ label, value }) => (
                            <MenuItem key={label} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>




                {user?.voiceInfo?.userType === "reseller" && (
                    <Stack
                        gap={2}
                        direction={"row"}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
                        <FormControl fullWidth>
                            <InputLabel id="request-select">Request For</InputLabel>
                            <Select
                                labelId="request-select"
                                id="request-select"
                                value={dndStatusRequestFor || ""}
                                onChange={(e) => setDndStatusRequestFor(e?.target?.value)}
                                label="Request For"
                                size="small"
                                displayEmpty
                            >
                                {requestList.map(({ label, value }) => (
                                    <MenuItem key={label} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                )}


                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction={"row"} gap={1}>
                        <IconButton onClick={() => handleReset("dndStatus")}>
                            <Iconify icon="ix:reset" />
                        </IconButton>
                        <LoadingButton
                            loading={dndLoader}
                            variant="contained"
                            sx={{ width: "100px" }}
                            onClick={() => handleSubmit("dndStatus")}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                </Box>
            </Box>




            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    mb: "15px",
                    border: "0.5px dashed",
                    borderColor: "gray.400",
                    borderRadius:"7px",
                    padding: "12px",
                    paddingTop: "14px"
                }}
            >
                <Box>
                    <Box sx={{
                        display: "flex",
                        gap: "6px",
                        mb: "15px",
                    }}>

                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Starting Range"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={startingRange}
                            onChange={(e) => setStartingRange(e.target.value)}
                            size="small"
                        />

                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Ending Range"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={endingRange}
                            onChange={(e) => setEndingRange(e.target.value)}
                            size="small"
                        />

                    </Box>
                    <Box sx={{
                        display: "flex",
                        gap: "6px",
                        mb: "15px",
                    }}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Start Cutting Percentage"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={startCuttingPercentage}
                            onChange={(e) => setStartCuttingPercentage(e.target.value)}
                            size="small"
                        />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="End Cutting Percentage"
                            type='number'
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                            value={endCuttingPercentage}
                            onChange={(e) => setEndCuttingPercentage(e.target.value)}
                            size="small"
                        />

                    </Box>
                </Box>

                <Stack width={"100%"} direction={"row"} alignItems={"center"}>
                    {user?.voiceInfo?.userType === "reseller" && (
                        <Stack
                            gap={2}
                            direction={"row"}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                mb: "10px"
                            }}
                        >
                            {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
                            <FormControl fullWidth>
                                <InputLabel id="request-select">Request For</InputLabel>
                                <Select
                                    labelId="request-select"
                                    id="request-select"
                                    value={cuttingRequestFor || ""}
                                    onChange={(e) => setCuttingRequestFor(e?.target?.value)}
                                    label="Request For"
                                    size="small"
                                    displayEmpty
                                >
                                    {requestList.map(({ label, value }) => (
                                        <MenuItem key={label} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        
                    )}
                   {/*  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"row"} gap={1}>
                            <IconButton onClick={() => handleReset("cutting")}>
                                <Iconify icon="ix:reset" />
                            </IconButton>
                            <LoadingButton
                                loading={cuttingLoader}
                                variant="contained"
                                sx={{ width: "100px" }}
                                onClick={() => handleSubmit("cutting")}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Box> */}
                </Stack>

                <Stack width={"100%"} direction={"row"} alignItems={"center"} >
                    {user?.voiceInfo?.userType === "reseller" && (
                        <Stack
                            gap={2}
                            direction={"row"}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "80%",

                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="mock-select">Mock to answer</InputLabel>
                                <Select
                                    labelId="mock-select"
                                    id="mock-select"
                                    value={mockAnswer}
                                    onChange={(e) => setMockAnswer(e?.target?.value)}
                                    label="Mock to answer"
                                    size="small"
                                >
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Stack direction={"row"} gap={1}>
                            <IconButton onClick={() => handleReset("cutting")}>
                                <Iconify icon="ix:reset" />
                            </IconButton>
                            <LoadingButton
                                loading={cuttingLoader}
                                variant="contained"
                                sx={{ width: "100px" }}
                                onClick={() => handleSubmit("cutting")}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Stack>


            </Box>





            {/* <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}>
                <LoadingButton
                    loading={loader}
                    variant="contained"
                    sx={{ width: "100px" }}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </LoadingButton>
            </Box> */}
        </Box>
        
        </>
    );
}

export default BroadcastSettings