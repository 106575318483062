import React, { createContext, useContext, useEffect, useState } from 'react';
import socketInstance from '../services/socket';
import NewLeadDialog from '../sections/kanban/new-lead-dialog';
import { useBoolean } from '../hooks/use-boolean';
import { useSnackbar } from 'src/components/snackbar';
import { TokenContext } from "../context/TokenContext";
import { ToastContainer } from 'react-toastify';

const SocketContext = createContext(null);

export const SocketProvider = ({ tokenValue, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [socket, setSocket] = useState(socketInstance);
  const [leadsData, setLeadsdata] = useState([]);
  const confirm = useBoolean();
  const { decodedToken } = useContext(TokenContext);

  useEffect(() => {
    if (tokenValue) {
      socket.connect();

      socket.on('connect', () => {
        // console.log('Socket connected.');
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });
    }

    socket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
    });

    if (Notification.permission === 'granted') {
      //  console.log("permission granted already")
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission()
    }
    socket.on('acceptnew_ticket', (data) => {
      const label = data?.newTicket?.labels[0] || "New Leads";
      setLeadsdata(data)
      const text = `${label} has been added to Leads`
      if (Notification.permission === 'granted') {
        new Notification(`New Lead Generated.`, {
          body: text,
          icon: ''
        });
      }
      confirm.onTrue()
    });

    socket.on('new_ticket', (data) => {
      const label = data?.newTicket?.labels[0] || "New Leads";
      const text = `${label} has been added to Leads`
      if (Notification.permission === 'granted') {
        new Notification(`New Lead Generated.`, {
          body: text,
          icon: ''
        });
      }
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
    };
  }, [socket]);

  const handleReject = (data) => {
    const { columnId, newTicket: { id } = {} } = data;
    const { profileUrl = "", userName = "", email = "" } = decodedToken;

    const newAssigneeData = { avatarUrl: profileUrl, name: userName, id: email };
    const dataToReject = { taskId: id, action: "PASSED", flag: 1, newAssignee: newAssigneeData, columnId, ticketType: "lead" };
    try {
      handleEmit(dataToReject, "update_assigneeuser", (err, response) => {
        if (!err) {
          enqueueSnackbar('This leads passed!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Error occurred during rejection!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
          });
        }
      })
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An unexpected error occurred!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
      });
    }
  };

  const handleAccept = (data) => {
    const { columnId, newTicket: { id, _id } = {} } = data;
    const dataToCheck = { taskId: id, action: "ACCEPTED", flag: 1, _id, columnId, ticketType: "lead" };

    const showNotification = (message, variant) => {
      enqueueSnackbar(message, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant,
      });
    };

    const handleResponse = (response) => {
      if (!decodedToken) {
        console.error("Decoded token is missing!");
        return;
      }

      const { profileUrl = "", userName = "", email = "" } = decodedToken;

      const newAssigneeData = { avatarUrl: profileUrl, name: userName, id: email };

      if (response === "You can accept this lead!") {
        handleEmit({ newAssignee: newAssigneeData, ...dataToCheck }, "update_assigneeuser", (err) => {
          if (!err) {
            showNotification("New lead has been accepted!", "success");
          } else {
            showNotification("Error occurred during acceptance!", "error");
          }
        });
      } else {
        showNotification("Someone accepted this lead!", "warning");
      }
    };

    try {
      handleEmit({ taskId: id }, "check_assignee", (err, response) => {
        if (err) {
          console.error(err);
          showNotification('An unexpected error occurred!', 'error');
        } else {
          handleResponse(response);
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An unexpected error occurred!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
      });
    }
  }

  const handleClose = (data) => {
    const { columnId, newTicket: { id } = {} } = data;
    const { profileUrl = "", userName = "", email = "" } = decodedToken;

    const newAssigneeData = { avatarUrl: profileUrl, name: userName, id: email };
    const dataToReject = { taskId: id, action: "No response", flag: 1, newAssignee: newAssigneeData, columnId, ticketType: "lead" };
    try {
      handleEmit(dataToReject, "update_assigneeuser", (err, response) => {
        if (!err) {
          enqueueSnackbar('This leads passed!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Error occurred during rejection!', {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            variant: 'error',
          });
        }
      })
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An unexpected error occurred!', {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        variant: 'error',
      });
    }
  };

  const handleEmit = (data, type, func) => {
    if (!socket) {
      func(true, "Please connect to server.")
      return
    } 
    data.ticketType = "lead";
    switch (type) {
      case "update_assigneeuser":
        socket.emit("update_assigneeuser", data, func); 
        break;
      case "check_assignee":
        socket.emit("check_assignee", data, func);
        break;
      default:
        func(true); 
    }
  }
 
  return (
    <>
      <ToastContainer style={{ marginTop: "40px" }} />
      <SocketContext.Provider value={socket}>
        {children}
      </SocketContext.Provider>
      <NewLeadDialog
        open={confirm?.value}
        setOpen={confirm?.onFalse}
        handleAccept={handleAccept}
        handleReject={handleReject}
        handleClose={handleClose}
        details={leadsData}
      />
    </>
  );
};

export const useSocket = () => useContext(SocketContext);
