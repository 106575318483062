import React, { useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import { ToastContainer } from 'react-toastify';
import { axiosPostCall } from '../../services/apisCall';
import FileUploader from '../advertisement/fileUploader';

export default function FileUploadDialog({ openDialog, handleClose, setOpenDialogUpload }) {
    const [selectedFile, setSelectedFile] = useState("");
    const [loadingState, setLoadingState] = useState(false);
    const signatureRef = useRef();

    const handleUpload = async () => {
        try {
            setLoadingState(true)
            if (!selectedFile) {
                setLoadingState(false)
                return renderErrorMessage("Please upload a signature!")
            }
            const formData = new FormData();
            formData.set('invoiceSignature', selectedFile);
            const { message } = await axiosPostCall("uploadInvoiceSignature", formData);
            if (message === "Success") {
                setLoadingState(false);
                setOpenDialogUpload(false);
                renderSuccessMessage(message);
                setSelectedFile(null)
            } else {
                renderErrorMessage("Error in uploading signature!")
                setSelectedFile(null);
                setLoadingState(false)
            }
        } catch (error) {
            console.error("error in upload signature!", error)
            renderErrorMessage(error.message);
            setLoadingState(false)
        }
    }

    return (
        <>
            {/* <ToastContainer /> */}

            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>Upload a File</DialogTitle>
                <DialogContent>
                    <FileUploader inputName="Select file"
                        inputId="invoiceSignature"
                        inputRef={signatureRef}
                        setFile={setSelectedFile}
                    />
                </DialogContent>
                <DialogActions sx={{ marginTop: '20px' }}>
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleUpload} color="success" variant="contained">
                        {loadingState ? <><CircularProgress size={17} sx={{ color: "error", marginRight: '8px' }} /> Uploading... </> : 'Upload'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
