import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AddUserSvg from '../assets/svgs/addUser';

import PermissionForm from '../sections/users/permissionForm';
import pageTitle from "../utils/pageTitles.json";
import { PermissionContext } from '../context/PermissionContext';
import DetailsForm from '../sections/users/detailsForm';
import { axiosGetCall, axiosPostCall } from '../services/apisCall';

import { renderErrorMessage, renderSuccessMessage } from '../lib/toastMessage';
import { ToastContainer } from 'react-toastify';

const logger = new Logger("kycListTable");
import Logger from '../lib/Logger';

//@mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';


const MainSection = styled('div')(() => ({
  width: '100%',
  padding: '64px 20px 0px 88px',
  backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
  height: '100vh'
}));

const MainSectionInner = styled('div')(() => ({
  backgroundColor: "#fff",
  padding: '30px 20px',
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  height: `calc(${window.innerHeight}px - 157px)`
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
  width: '100%',
  padding: "20px 0px 20px 0px",
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row'
}));

const AddNewUser = () => {

  const { permission: { access: adminAccess = {}, tabPanel = {} } } = useContext(PermissionContext)

  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("")
  const [userType, setUserType] = useState("")
  const [group, setGroup] = useState("");
  const { edit, email: userEmail } = location?.state || {}
  const [addUserLoader, setAddUserLoader] = useState(false)

  const [accessArray, setAccessArray] = useState({})

  const getUserAccess = async () => {
    try {

      const params = { email: userEmail }
      const { access, role, group } = await axiosGetCall("getUserAccess", params)

      Object.keys(adminAccess).map((id) => {
        if (!access[id]) {
          const cloneArray = JSON.parse(JSON.stringify(adminAccess[id]));
          cloneArray.r = false;
          cloneArray.w = false;
          access[id] = cloneArray;
        }
      })
      setAccessArray(access)
      setUserType(role)
      setEmail(userEmail)
      setGroup(group)
    } catch (error) {
      logger.error("addNewUser.jsx ,handleAddUser getUserAccess", error);
      renderErrorMessage(error?.message)
    }
  }

  useEffect(() => {

    const newArray = {};

    Object.keys(adminAccess).map((key) => {
      if (adminAccess[key].r && adminAccess[key].w) {
        const cloneArray = JSON.parse(JSON.stringify(adminAccess[key]))
        cloneArray.r = false;
        cloneArray.w = false;
        newArray[key] = cloneArray;
      }
    })

    setAccessArray(newArray);
  }, [adminAccess])

  useEffect(() => {
    if (edit) {
      getUserAccess();
    }
  }, [])

  const handleAccess = (page, key, accessType, bool) => {
    let object = { ...access }
    object[page][key][accessType] = bool
    setAccess(object)
  }

  const handleAddUser = async () => {
    try {

      setAddUserLoader(true)

      const cloneArray = JSON.parse(JSON.stringify(accessArray));

      Object.keys(cloneArray).map((id) => {
        delete cloneArray[id].title;
      })

      Object.keys(cloneArray).map((id) => {
        if (!cloneArray[id].r && !cloneArray[id].w) delete cloneArray[id];
      })

      const params = { access: cloneArray, role: userType, email, group };
      let message;

      if (edit) {
        message = await axiosPostCall("updateUserDetails", params)
      }
      else {
        message = await axiosPostCall("createAdminUser", params)
      }
      renderSuccessMessage(message?.message)
      setTimeout(() => {
        navigate("/users")
      }, 1000);

    } catch (error) {
      logger.error("addNewUser.jsx ,handleAddUser ,createAdminUser", error);
      renderErrorMessage(error?.message)
    } finally {
      setAddUserLoader(false)
    }
  }

  return (
    <>
      <Helmet>
        <title> {`${pageTitle?.addNewUser}`} </title>
      </Helmet>
      {/* <ToastContainer /> */}
      <MainSection>

        <HeadingSection>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{
              widht: '16px',
              height: '16px',
              mt: -1.8
            }}>
              <AddUserSvg />
            </Box>
            <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
              Add New User
            </Typography>
          </Box>

        </HeadingSection>

        <MainSectionInner>
          <DetailsForm addUserLoader={addUserLoader} email={email} setEmail={setEmail} userType={userType} setUserType={setUserType} group={group} setGroup={setGroup} handleAddUser={handleAddUser} edit={edit} />

          {Object.keys(accessArray)?.length > 0 && <PermissionForm
            accessArray={accessArray}
            setAccessArray={setAccessArray}
            tabPanel={tabPanel}
            adminAccess={adminAccess}
            handleAccess={handleAccess}
            edit={edit}

          />}
        </MainSectionInner>
      </MainSection>
    </>
  )
}

export default AddNewUser;