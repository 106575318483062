import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import { LoadingButton as Button } from '@mui/lab';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />
});

const NewLeadDialog = ({ open, setOpen, handleAccept, handleReject, handleClose, details }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClosee = () => {
        setOpen(false);
    };
    const handleConnect = () => {
        setOpen(false);
        handleAccept(details)
    }

    const handlePass = () => {
        details.pass = 'rejetced'
        setOpen(false);
        handleReject(details)
    }
    React.useEffect(() => {
        if (open) {
            const timeoutId = setTimeout(() => {
                details.pass = 'No response'
                handleClosee();
                // handleClose(details)
            }, 116000);

            return () => clearTimeout(timeoutId);
        }
    }, [open]);
    return (
        <Dialog
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            open={open}
            aria-labelledby="responsive-dialog-title"
            keepMounted
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                    justifyContent: 'flex-end'
                },
                "& .MuiPaper-root": {
                    margin: '10px',
                    boxShadow: '0px 2px 0px -7px rgba(0,0,0,0.2),0px 1px 38px 3px rgba(0,0,0,0.14),0px 1px 65px 1px rgba(0,0,0,0.12)',
                    minWidth: '400px'
                }
            }}
            hideBackdrop={true}
        >
            <DialogTitle id="responsive-dialog-title" sx={{ color: 'grey.800' }}>
                {"New Leads Generated!"}
            </DialogTitle>

            <DialogContent>
                <DialogContentText sx={{ color: 'grey.600' }}>
                    {details?.newTicket?.labels[0]} has been added to New Leads!
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ mb: '10px' }}>

                <Button
                    onClick={() => handleConnect(details)}
                    autoFocus
                    variant='contained'
                    size='small'
                    color='success'
                    sx={{ color: '#fff' }}
                >
                    Accept
                </Button>

                <Button
                    onClick={() => handlePass(details)}
                    autoFocus
                    variant='outlined'
                    size='small'
                    color={'error'}
                >
                    Pass
                </Button>

                {/*  <Button
                    autoFocus
                    onClick={handlePass}
                    size='small'
                    color='warning'
                    variant='outlined'
                >
                    Pass
                </Button> */}

                {/* <Button
                    onClick={() => handleClosee()}
                    variant='outlined'
                    // sx={{ color: 'grey.400', borderColor: 'grey.400' }}
                    size='small'
                >
                    Close
                </Button> */}
            </DialogActions>
        </Dialog>
    )
}

export default NewLeadDialog