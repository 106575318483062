import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';
const logger = new Logger("kycListTable");
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import DeleteIcon from '@mui/icons-material/Delete';

// component
import { PaginationTable } from '../../components/tables/paginationTable';
import TableFilters from './tableFilter';

//mui 
import { Box, List, ListItem, TableCell } from '@mui/material';

//mui icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockIcon from '@mui/icons-material/Block';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import LogoutIcon from '@mui/icons-material/Logout';
import { Icon } from '@iconify/react';

import CustomTabPanel from './customTabPanel';
import { useNavigate } from 'react-router-dom';
import KycDetailsModal from '../kyc/kycDetailsModal';
import { CustomMenu } from '../../components/menu/customMenu';
import { checkUserRole } from '../../utils/common';


const columns = [
    { id: 'email', label: 'Email', minWidth: 110, align: 'start' },
    { id: 'userName', label: 'Name', minWidth: 110, align: 'center' },
    { id: 'createdAt', label: 'Created Date', minWidth: 110, align: 'center' },
    { id: 'createdBy', label: 'Created By', minWidth: 110, align: 'center' },
    { id: 'role', label: 'Role', minWidth: 110, align: 'center' },
    { id: 'group', label: 'Group', minWidth: 110, align: 'center' },
    { id: 'status', label: 'Status', minWidth: 110, align: 'center' },
    { id: 'hierarchy', label: 'Hierarchy', minWidth: 110, align: 'center' },
    { id: 'actions', label: 'Action', minWidth: 20, align: 'center' },
];

export const AdminUserListTable = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)
    const [selectedPanelStatus, setSelectedPanelStatus] = useState("")

    const [search, setSearch] = useState("")
    const [load, setLoad] = useState(true)
    const [networkError, setNetworkError] = useState(false)
    const [loading, setLoading] = useState(true)

    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [selectedEmail, setSelectedEmail] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")

    const handleClick = (event, email, status) => {
        setSelectedStatus(status);
        setAnchorEl(event.currentTarget);
        setSelectedEmail(email)
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setLoad(true)
    }

    const handleChange = (value) => setSearch(value)

    const getAdminUserList = async () => {
        try {
            const skip = rowsPerPage * page;
            const params = { skip, limit: rowsPerPage }
            if (checkUserRole() === "admin") params.userRole = "agent";
            if (search) params.email = search
            if (selectedPanelStatus) params.status = selectedPanelStatus
            setNetworkError(false)
            const { result, totalCount } = await axiosGetCall("adminUsersList", params)
            setRows(result)
            setCount(totalCount)
        } catch (error) {
            logger.error("AdminUserLIstTable.jsx ,getAdminUserList ,adminUsersList", error);
            setNetworkError(true)
            setRows([])
            setCount(0)
            renderErrorMessage(error?.message)
        }
        setLoading(false)
    }

    const updateUserStatus = async () => {
        try {
            const params = { email: selectedEmail }
            if (selectedStatus === "active" || selectedStatus === "pending") params.status = "deActivate";
            if (selectedStatus === "deActivate") params.status = "active"
            const { message } = await axiosGetCall("updateUserStatus", params)
            renderSuccessMessage(message)
            setAnchorEl(null);
            getAdminUserList();
        } catch (error) {
            logger.error("AdminUserLIstTable.jsx ,updateUserStatus ,updateUserStatus", error);
            renderErrorMessage(error?.message)
        }
    }

    const forceLogout = async () => {
        try {
            const { message } = await axiosPostCall("forceLogout", { email: selectedEmail })
            renderSuccessMessage(message)
            setAnchorEl(null);
            getAdminUserList();
        } catch (error) {
            logger.error("AdminUserLIstTable.jsx ,forceLogout ,forceLogout", error);
            renderErrorMessage(error?.message)
        }
    }

    useEffect(() => {
        if (load) {
            setLoading(true)
            getAdminUserList()
            setLoad(false)
        }
    }, [load])

    const tableFilter = <TableFilters search={search} handleChange={handleChange} setLoad={setLoad} count={count} />

    const ActionJsx = (id, align, value, email, status) => (
        <TableCell
            key={id}
            align={align}
            sx={{
                color: 'grey.400',
                borderBottom: '0.5px dashed',
                borderColor: 'grey.300',

            }}>
            <MoreVertIcon
                sx={{ color: 'grey.700', height: '18px', width: '18px', cursor: 'pointer' }}
                aria-controls={open ? 'filters-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => handleClick(e, email, status)}
            />
        </TableCell>
    )
    const contentStyle = { height: '20px', width: '20px', marginRight: '5px' }
    const Content = () => (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
        }}>
            <List>
                <ListItem sx={{
                    display: 'flex',
                    gap: '7px',
                    fontWeight: 400,
                    color: 'grey.700',
                    '&:hover': {
                        color: 'grey.800',
                        backgroundColor: 'grey.300',
                        cursor: "pointer"
                    }
                }}
                    onClick={() => updateUserStatus()}
                >
                    {selectedStatus === "deActivate" ?
                        <RadioButtonCheckedIcon sx={{ color: 'error.main', ...contentStyle }} />
                        : <BlockIcon sx={{ ...contentStyle, color: 'error.main' }} />}
                    {selectedStatus === "deActivate" ? "Activate" : "De-Activate"}
                </ListItem>

                <ListItem sx={{
                    display: 'flex',
                    gap: '7px',
                    fontWeight: 400,
                    color: 'primary',
                    '&:hover': {
                        color: 'grey.800',
                        backgroundColor: 'grey.300',
                        cursor: "pointer"
                    }

                }}
                    onClick={() => navigate("/add_new_user", { state: { edit: true, email: selectedEmail } })}
                >
                    <Icon icon="lucide:edit" style={{ ...contentStyle, color: '#1976d2', height: '17px' }} />
                    Edit
                </ListItem>
                <ListItem sx={{
                    display: 'flex',
                    gap: '7px',
                    fontWeight: 400,
                    color: 'grey.700',
                    '&:hover': {
                        color: 'grey.800',
                        backgroundColor: 'grey.300',
                        cursor: "pointer"
                    }

                }}
                    onClick={() => forceLogout()}
                >
                    <DeleteIcon sx={{ ...contentStyle, color: 'red' }} />
                    Delete
                </ListItem>
                <ListItem sx={{
                    display: 'flex',
                    gap: '7px',
                    fontWeight: 400,
                    color: 'grey.700',
                    '&:hover': {
                        color: 'grey.800',
                        backgroundColor: 'grey.300',
                        cursor: "pointer"
                    }

                }}
                    onClick={() => forceLogout()}
                >
                    <LogoutIcon sx={{ ...contentStyle, color: "#f44336", marginLeft: '2px' }} />
                    Force Logout
                </ListItem>
            </List>
        </Box>
    )

    const ActionModals = () => {
        return (
            <>
                <CustomMenu
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    Content={Content} />

                {openModal && <KycDetailsModal
                    open={openModal}
                    close={() => { handleModalClose() }}
                    userId={selectedUserId}
                />}
            </>
        )
    }

    return (
        <>
            {/* <ToastContainer /> */}
            <CustomTabPanel setSelectedPanelStatus={setSelectedPanelStatus} setLoad={setLoad} />
            <PaginationTable
                page={page}
                tableFilter={tableFilter}
                ActionJsx={ActionJsx}
                ActionModals={ActionModals}
                setLoad={setLoad}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                error={networkError}
                loading={loading}
                setLoading={setLoading}
                rows={rows}
                columns={columns}
                handleChange={handleChange}
                search={search}
                size={'small'}
                calculatedHeight={337}
            />
        </>
    )
}
