import React from "react";
import ReleaseData from "../../src/release.json"
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
} from "@mui/material";
import { CheckCircle, BugReport, TrendingUp } from "@mui/icons-material";

const ReleasePage = () => {
  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", p: 3, mt: "60px" }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" mb={4}>
        🚀 Release Notes
      </Typography>
      {ReleaseData.versions.map((release, index) => (
        <Card key={index} sx={{ mb: 3, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" fontWeight="bold">
              {release.version} - {release.releaseDate}
            </Typography>

           {release.features && release.features.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
                  New Features 🎉
                </Typography>
                <List>
                  {release.features.map((feature, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <CheckCircle color="success" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ my: 2 }} />
              </>
            )}

        {release.optimizations && release.optimizations.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
                  Improvements 🚀
                </Typography>
                <List>
                  {release.optimizations.map((optimization, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <TrendingUp color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={optimization} />
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ my: 2 }} />
              </>
            )}

            {release.bugs && release.bugs.length > 0 && (
              <>
                <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
                  Bug Fixes 🐞
                </Typography>
                <List>
                  {release.bugs.map((bug, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <BugReport color="error" />
                      </ListItemIcon>
                      <ListItemText primary={bug} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ReleasePage;


