import { useState } from "react";
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, Radio
} from "@mui/material";

const TableComponent = ({ title, headers, data, keyMapping, id, setId }) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSelect = (index, _id) => {
    setSelectedRow(selectedRow === index ? null : index);
    // setId(_id)
  };

  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      {title && <Typography variant="h6" sx={{ padding: 2 }}>{title}</Typography>}
      <Table sx={{ width: "100%", tableLayout: "fixed" }}>
        {data?.length > 0 && (<TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            <TableCell >In Use</TableCell>
            {headers?.map((header, index) => (
              <TableCell key={index} sx={{ textAlign: "center" }}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>)}
        <TableBody>
          {data?.length > 0 && (
            data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Radio
                    checked={selectedRow === index}
                    onClick={() => handleSelect(index, item._id)}
                  />
                </TableCell>
                {headers?.map((header, idx) => (
                  <TableCell key={idx} sx={{ textAlign: "center" }}>
                    {keyMapping[header] !== undefined
                      ? item[keyMapping[header]] !== null && item[keyMapping[header]] !== undefined && item[keyMapping[header]] !== ""
                        ? item[keyMapping[header]]
                        : "--"
                      : "--"}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;