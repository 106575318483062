import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { axiosPostCall, axiosGetCall } from '../../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';

//mui
import { Box, Button, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, TableContainer, Table, TableHead, TableRow, Popover, Paper, TableBody, Tooltip, Chip, styled } from '@mui/material';

//logger
import Logger from '../../lib/Logger';
import { PaginationTable } from './paginationTable';
import { useBoolean } from 'src/hooks/use-boolean';
import PlanForm from './CreatePlanModal';
import Iconify from 'src/components/iconify';
import UserFilters from './filters';
const logger = new Logger("planListTable");

const Columns = [
    { id: 'product_id', label: 'Product ID', minWidth: 100, align: 'center' },
    { id: 'product_name', label: 'Product Name', minWidth: 110, align: 'center' },
    { id: "plan_id", label: "Plan ID", minWidth: 110, align: "center" },
    { id: "plan_name", label: "Plan Name", minWidth: 110, align: "center" },
    { id: 'price', label: 'Price', minWidth: 110, align: 'center' },
    { id: "max_user", label: "Max User", minWidth: 110, align: "center" },
    { id: "active", label: "Status", minWidth: 110, align: "center" },
    { id: 'actions', label: 'Action', minWidth: 110, align: 'center' },
];

const HeadingSection = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        flexWrap: "wrap",
    },
}));

export const PlansListTable = ({ forceReload }) => {

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState("")
    const [load, setLoad] = useState(true)
    const [networkError, setNetworkError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [confirmModal, setConfirmModal] = useState(false)
    const [filterLabels, setFilterLabels] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [filterData, setFilterData] = useState({
        status: "active",
        planId: "",
        productId: ""
    });

    const planFormModal = useBoolean()

    const onPlanUpdate = (row) => {
        setSelectedPlan(row)
        planFormModal.onTrue()
    }

    const onPlanStatusChange = (row) => {
        setSelectedPlan(row)
        setConfirmModal(true)
    }

    const resetFilters = () => {
        setFilterLabels([]);
        setSearch("");
        setFilterData({
            status: "active",
            planId: "",
            productId: ""
        });
        setIsFilterApplied(false);
        setLoad(true);
    };

    const reset = () => {
        resetFilters();
    };

    const apply = (data) => {
        setRows([]);
        setSearch("");
        setIsFilterApplied(true);

        const label = [];

        Object.keys(data).forEach((element) => {
            data[element] &&
                label.push({ key: filterLabelMap[element], value: data[element] });

        });
        setFilterLabels(label);

        const newData = JSON.parse(JSON.stringify(data));
        setFilterData(newData);
        setPage(0);
        setLoad(true);
    };

    const generateLabelChipToolTip = (item) => {
        if (["From", "To"].includes(item?.key))
            return moment(item?.value).format("D MMM, YYYY");
        else return item?.value;
    };

    const filterLabelMap = {
        status: "Status",
        planId: "PlanId",
        productId: "ProductId",
    };

    const handlePlanUpdate = async (data, setState) => {
        try {
            setState(true)

            const { product_id, plan_id, slabs } = data

            const payload = {
                productId: product_id,
                planId: plan_id,
                updatedPlan: data,
            }
            if(product_id === "Broadcast") {
                payload.slabs = slabs;
                delete data.slabs;
                payload.updatedPlan = data;
            }
            const { message } = await axiosPostCall("updatePlan", payload)
            renderSuccessMessage(message)
            planFormModal.onFalse();
            setLoad(true)

        } catch (error) {
            logger.error("plansListTable.jsx ,handlePlanUpdate ,updatePlan api", error);
            renderErrorMessage(error.message)

        }
        finally {
            setState(false)
        }
    }

    const handlePlanStatusUpdate = async (active) => {
        try {
            const { product_id, plan_id } = selectedPlan;
            const payload = {
                productId: product_id,
                planId: plan_id,
                active
            }
            const { message } = await axiosPostCall("deletePlan", payload)
            setConfirmModal(false)
            setLoad(true)
            renderSuccessMessage(message)

        } catch (error) {
            logger.error("plansListTable.jsx ,handlePlanStatusUpdate ,deletePlan api", error);
            renderErrorMessage(error.message)
        }
    }

    const findForSearchQuery = (search) => {
        setSearch(search);
        if (search?.trim()) {
            setPage(0);
            setLoad(true);
            if (!filterLabels.includes("search"))
                setFilterLabels([{ key: "search", value: search }]);
            setIsFilterApplied(false);
            return;
        }
        const updatedLabels = filterLabels.filter((item) => item?.key !== "search");
        setFilterLabels([...updatedLabels]);
        setRows([]);
        setIsFilterApplied(false);
        setPage(0);
        setLoad(true);
    };

    const handleChange = (value) => setSearch(value)

    const getPlansList = async () => {
        const skip = rowsPerPage * page;
        try {
            let params = { skip, limit: rowsPerPage }
            if (search) params.search = search

            const statusMapper = {
                active: true,
                inactive: false,
                all: "all"
            }

            const filter = JSON.parse(JSON.stringify(filterData));

            if (filter?.status) params.status = statusMapper[filterData?.status]
            delete filter.status;
            setNetworkError(false)

            params = { ...params, ...filter }

            const data = await axiosGetCall("getPlanList", params)
            setLoading(false);

            const { planList: { result, total }, products } = data;

            setRows(result)
            setCount(total)

        } catch (error) {
            logger.error("PlansListTable.jsx ,getPlansList ,getPlanList", error);
            setNetworkError(true)
            setRows([])
            setCount(0)
            renderErrorMessage(error?.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (load) {
            setLoading(true);
            getPlansList();
        }
        setLoad(false)
    }, [load])


    useEffect(() => {
        getPlansList();

    }, [forceReload.value])

    const tableFilter = (
        <HeadingSection>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                }}
            >
                {/* <HeadingTypography>Customers</HeadingTypography> */}

                {!!filterLabels.length && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "6px",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        {filterLabels.map((item) => (
                            <Tooltip title={generateLabelChipToolTip(item)}>
                                <Chip label={item?.key} variant="outlined" size="small" />
                            </Tooltip>
                        ))}

                        <Button
                            variant="outlined"
                            size="small"
                            color="warning"
                            onClick={() => resetFilters()}
                        >
                            Clear Filter
                        </Button>
                    </Box>
                )}
            </Box>

            <UserFilters
                findForSearchQuery={findForSearchQuery}
                getPlansList={getPlansList}
                apply={apply}
                type="user"
                reset={reset}
                search={search}
                isFilterApplied={isFilterApplied}
                filterData={filterData}
                setFilterData={setFilterData}
            />
        </HeadingSection >
    )

    const ActionJsx = ({ id, align, value, row }) => {
        return (
            <TableCell
                key={id}
                align={align}
                sx={{
                    color: 'grey.400',
                    borderBottom: '0.5px dashed',
                    borderColor: 'grey.300',
                    size: 'small'
                }}>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px"
                    }}
                >
                    <Tooltip title="Update Plan">
                        <IconButton
                            onClick={() => onPlanUpdate(row)}
                            color='success'
                        >
                            <Iconify icon="clarity:update-line" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={row?.active ? "Deactive" : "Activate"}>
                        <IconButton
                            onClick={(event) => onPlanStatusChange(row)}
                            color={row?.active ? 'error' : 'success'}
                        >
                            <Iconify icon={row?.active ? "ion:stop-circle-outline" : "mingcute:play-fill"} />
                        </IconButton>
                    </Tooltip>

                </Box>
            </TableCell>
        )
    }

    const Confirmation = () => (
        <Dialog open={confirmModal} PaperProps={{
            sx: {
                width: "370px",
                maxHeight: "180px"
            }
        }}>
            <DialogTitle >Update Plan Status</DialogTitle>
            <DialogContent sx={{ marginTop: '-13px' }}>
                {selectedPlan?.active ? (<Typography>  would you like to <span style={{ fontWeight: 'bold' }}>{'Deactivate '}</span>this plan?</Typography>)
                    : (<Typography>Are you sure you want to <span style={{ fontWeight: 'bold' }}>{'Activate '}</span>this Plan?</Typography>)
                }          </DialogContent>
            <DialogActions sx={{
                marginTop: "-6px",
                marginBottom: "5px",
            }}>
                {selectedPlan?.active ? <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        color: '#fff',
                        height: '30px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                        },
                        boxShadow: "none",
                        marginRight: '10px'
                    }}
                    onClick={() => handlePlanStatusUpdate(false)}
                >
                    DeActivate
                </Button> : <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        color: '#fff',
                        height: '30px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                        },
                        boxShadow: "none",
                        marginRight: '10px'
                    }}
                    onClick={() => handlePlanStatusUpdate(true)}
                >
                    Activate
                </Button>}
                <Button
                    variant="outlined"
                    sx={{
                        height: '30px',
                        borderColor: 'red',
                        color: 'red',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                            borderColor: 'red',
                        }
                    }}
                    onClick={() => setConfirmModal(false)}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

    const ActionModals = () => {
        return (
            <>
                <PlanForm open={planFormModal.value} onClose={() => { planFormModal.onFalse(); setSelectedPlan(null) }} plan={selectedPlan} edit={!!selectedPlan} handlePlanUpdate={handlePlanUpdate} />
            </>
        )

    }

    return (
        <>
            {/* <ToastContainer /> */}
            <Confirmation />
            <PaginationTable
                page={page}
                tableFilter={tableFilter}
                ActionJsx={ActionJsx}
                ActionModals={ActionModals}
                setLoad={setLoad}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                error={networkError}
                loading={loading}
                setLoading={setLoading}
                rows={rows}
                columns={Columns}
                handleChange={handleChange}
                search={search}
                calculatedHeight={377}
            />
        </>
    )
}