import React, { useState, } from 'react';
import { Typography, Box, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import { ToastContainer } from 'react-toastify';
import { axiosPostCall } from 'src/services/apisCall';
import CompanyTable from './TableComponent';

const CompanyDetail = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            companyName: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
            gstin: "",
            email: "",
            phone: "",
        },
        validationSchema: Yup.object({
            companyName: Yup.string()
                .min(2, "Company Name must be at least 2 characters")
                .max(100, "Company Name cannot exceed 100 characters")
                .matches(/^[a-zA-Z0-9\s.,&]+$/, "Company Name can only contain letters, numbers, spaces, dots, commas, and '&'")
                .test("not-only-numbers", "Company Name cannot be only numbers", (value) => isNaN(value))
                .required("Company Name is required"),

            address: Yup.string()
                .min(10, "Address must be at least 10 characters long")
                .max(200, "Address cannot exceed 200 characters")
                .matches(/^[a-zA-Z0-9\s,.-]+$/, "Address can only contain letters, numbers, spaces, commas, dots, and hyphens")
                .required("Address is required"),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            country: Yup.string().required("Country is required"),
            zipCode: Yup.string().matches(/^\d{6}$/, "Zipcode must be exactly 6 digits")
                .required("Zipcode is required"),
            gstin: Yup.string()
                .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, "Invalid GSTIN format")
                .required("GSTIN is required"),
            email: Yup.string().matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "Invalid email format"
            )
                .required("Email is required"),
            phone: Yup.string().matches(/^\d{10}$/, "Contact Number must be 10 digits")
                .required("Contact Number is required"),

        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            try {
                const companyDetail = {
                    formData: {
                        "name": values.companyName,
                        "email": values.email,
                        "address": values.address,
                        "city": values.city,
                        "country": values.country,
                        "gstin": values.gstin,
                        "phone": values.phone,
                        "state": values.state,
                        "zipcode": values.zipCode
                    }
                }

                const result = await axiosPostCall("addCompanyDetails", companyDetail);
                
                if (result.message === "Success") {
                    resetForm();     
                    renderSuccessMessage("success");
                    setOpen(false);
                }
                 else {
                    renderErrorMessage(result.message);
                }

            } catch (error) {
                console.error("Error submitting form:", error);
                renderErrorMessage(error.message);
            } finally {
                setLoading(false);
            }
        },
    });
    return (
        <>
            {/* <ToastContainer /> */}
            <Button variant="contained" onClick={() => setOpen(true)}>
                <Typography variant="h5" component="span" sx={{ fontWeight: "bold", marginRight: "5px" }}>+</Typography>
                Add Company Details
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Company Details Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>

                            <TextField
                                label="Company Name *"
                                name="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                helperText={formik.touched.companyName && formik.errors.companyName}
                            />

                            <TextField
                                label="Address *"
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />

                            <TextField
                                label="City *"
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                            />

                            <TextField
                                label="State *"
                                name="state"
                                value={formik.values.state}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                            />

                            <TextField
                                label="Country *"
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}
                            />

                            <TextField
                                label="ZipCode *"
                                name="zipCode"
                                value={formik.values.zipCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                                helperText={formik.touched.zipCode && formik.errors.zipCode}
                            />

                            <TextField
                                label="GST Number *"
                                name="gstin"
                                value={formik.values.gstin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                                helperText={formik.touched.gstin && formik.errors.gstin}
                            />

                            <TextField
                                label="Email *"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />

                            <TextField
                                label="Phone *"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                fullWidth
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />

                            <DialogActions>
                                <Button onClick={() => setOpen(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                    {loading ? <><CircularProgress size={24} color="inherit" /> Uploading... </> : "Submit"}
                                </Button>
                            </DialogActions>

                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
            <CompanyTable  />
        </>
    )
};

export default CompanyDetail;