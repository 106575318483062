import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { axiosPostCall } from '../services/apisCall';
import { setLoginSession } from '../utils/common';
import { ToastContainer } from 'react-toastify';
import { renderErrorMessage } from '../lib/toastMessage';
import { Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { jwtDecode } from 'jwt-decode';
import { CircularProgress } from '@mui/material'


const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(6, 'Must be at least 6 characters').required('Required'),
    }), 
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { email, password } = values;
        const { exp, sessionToken, token, message } = await axiosPostCall("login", { email, password });
        renderErrorMessage(message);
        if (!exp || !sessionToken || !token) {
          return window.location.href = "/";
        }
        setLoading(false);
        setLoginSession(exp, sessionToken);
        localStorage.setItem("token", token);
        const decodedToken = jwtDecode(token);

        const userAccess = decodedToken.access || {};
        const routesMap = [
          { path: "/home", id: 2002 },
          { path: "/leads", id: 5001 },
          { path: "/kyc", id: 1001 },
          { path: "/users", id: 1003 },
          { path: "/plans", id: 9002 },
          { path: "/billing", id: 9003 },
          { path: "/updatepermissions", id: 7001 },
          { path: "/console_users", id: 1002 },
          { path: "/advertisement", id: 4002 },
          { path: "/branding", id: 9004 },
          { path: "/announcementList", id: 9001 },
          { path: "/meet", id: 3001 },
          { path: "/webinar", id: 3002 },
          { path: "/sms", id: 3003 },
          { path: "/profile", id: 2001 },
          { path: "/add_new_user", id: 4001 },
          { path: "/leads_analysis", id: 6001 },
          { path: "/createUser", id: 8001 },
        ];

        const firstAccessibleRoute = routesMap.find(route => userAccess[route.id]?.r);

        const redirectPath = firstAccessibleRoute ? firstAccessibleRoute.path : "/";
        return window.location.href = redirectPath;
      } catch (error) {
        console.error(error);
        renderErrorMessage(error.message);
      }
    },
  });

  return (
    <>
      {/* <ToastContainer /> */}
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "40px",
            width: "100%",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <h1 style={{ margin: 0, fontSize: "24px", fontWeight: "bold", color: "#333" }}>
            Welcome Back
          </h1>
          <p style={{ margin: "10px 0 20px", color: "#666" }}>
            Sign in to access your account
          </p>
          <form onSubmit={formik.handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: "20px", textAlign: "left" }}>
              <label style={{ marginBottom: "5px", fontSize: "14px", color: "#333" }}>
                Email
              </label>
              <input
                type="email"
                name="email"
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  fontSize: "16px",
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Enter your email"
              />
              {formik.touched.email && formik.errors.email && (
                <div style={{ marginTop: "5px", color: "#ff0000", fontSize: "12px" }}>
                  {formik.errors.email}
                </div>
              )}
            </div>
            <div style={{ marginBottom: "20px", textAlign: "left" }}>
              <label style={{ marginBottom: "5px", fontSize: "14px", color: "#333" }}>
                Password
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    fontSize: "16px",
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder="Enter your password"
                />
                <span
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    color: '#007bff',
                  }}
                  onClick={() => setShowPassword(prev => !prev)}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </span>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div style={{ marginTop: "5px", color: "#ff0000", fontSize: "12px" }}>
                  {formik.errors.password}
                </div>
              )}
            </div>
            {loading ? (
              <button
                type="button"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  fontSize: "16px",
                  cursor: "not-allowed",
                  fontWeight: "bolder",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled
              >
                <CircularProgress size={17} sx={{ color: "#fff", marginRight: "8px" }} />
                Logging...
              </button>
            ) : (
              <button
                type="submit"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: "bolder",
                  transition: "background-color 0.3s ease",
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = "#007bff")}
              >
                Login
              </button>
            )}

          </form>
        </div>
      </Box>
    </>
  );
};

export default Login;
