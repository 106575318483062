// All apis base url
export function getApiUrl() {
  return "http://localhost:4000/";
  return "http://localhost:4000/";
}

// Get api endpoints dynamically
export function endPoints(title) {
  const version = 'v1/';
  const routes = {
    login: `${version}auth/login`,
    logout: `${version}auth/logout`,
    fetchKycUserList: `${version}kyc/fetchKycUserList`,
    getUserKycDoc: `${version}kyc/getUserKycDoc`,
    updateKycDocStatus: `${version}kyc/updateKycDocStatus`,
    updateKycStatus: `${version}kyc/updateKycStatus`,
    sendRejectedMail: `${version}kyc/sendRejectedMail`,
    updateKycStatus: `${version}kyc/updateKycStatus`,
    consoleUsersList: `${version}users/consoleUsersList`,
    adminUsersList: `${version}admin/adminUsersList`,
    createAdminUser: `${version}admin/createUser`,
    advertisement: `${version}advertisment/upload`,
    consoleReferenceLogin: `${version}mis/consoleReferenceLogin`,
    getUserAccess: `${version}admin/getUserAccess`,
    updateUserDetails: `${version}admin/updateUserDetails`,
    updateUserStatus: `${version}admin/updateUserStatus`,
    getAgentDetails: `${version}crm/getAgentsDetails`,
    forceLogout: `${version}admin/forceLogout`,
    getCrmActivityLogs: `${version}crm/getCrmActivityLogs`,
    updateProfile: `${version}admin/updateProfile`,
    updateForceVerifyPermission: `${version}users/updateForceVerifyPermission`,
    getUserPassword: `${version}users/getUserPassword`,
    getAllAdmin: `${version}admin/getAllAdmin`,
    updateUserAdmin: `${version}users/updateUserAdmin`,
    updatePanelPermission: `${version}admin/updatePanelPermission`,
    getPanelPermission: `${version}admin/getPanelPermission`,
    createConsoleUser: `${version}admin/createConsoleUser`,
    fetchToken: `${version}auth/fetchToken`,
    fetchAnnouncementList: `${version}voice/fetchAnnouncementList`,
    updateAnnouncementStatus: `${version}voice/updateAnnouncementStatus`,
    fetchIvrList: `${version}voice/fetchIvrList`,
    updateIvrStatus: `${version}voice/updateIvrStatus`,
    updateConsoleUser: `${version}admin/updateConsoleUser`,
    consoleUserDetails: `${version}admin/consoleUserDetails`,
    getVoiceUserPlanNCredits: `${version}voice/getVoiceUserPlanNCredits`,
    getVoicePlanNCredits: `${version}voice/getVoicePlanNCredits`,
    assignPlanToVoiceUser: `${version}voice/assignPlanToVoiceUser`,
    removeVoiceUserPlan: `${version}voice/removeVoiceUserPlan`,
    assignCreditToVoiceUser: `${version}voice/assignCreditToVoiceUser`,
    assignTTSCreditToVoiceUser: `${version}voice/assignTTSCreditToVoiceUser`,
    getPlanList: `${version}plan/getPlanList`,
    updatePlan: `${version}plan/updatePlan`,
    addNewPlan: `${version}plan/addNewPlan`,
    deletePlan: `${version}plan/updatePlanStatus`,
    createInvoice: `${version}invoice/createInvoice`,
    branding: `${version}mis/branding`,
    assignAMpass: `${version}admin/pass`,
    getUserSettingsInfo: `${version}voice/getUserSettingIfno`,
    updateProfileSetting: `${version}voice/updateProfileSetting`,
    tokenGenerate: `${version}voice/generateToken`,
    fetchTtsTemplate: `${version}voice/fetchTtsTemplates`,
    updateTemplateStatus: `${version}voice/updateTemplateStatus`,
    getTranslatedText: `${version}voice/getTranslatedText`,
    amAnalysis: `${version}admin/amAnalysis`,
    sendInvoice: `${version}invoice/sendInvoice`,
    getQuotationList: `${version}invoice/getQuotationList`,
    sendTaxInvoice:`${version}invoice/sendTaxInvoice`,
    slabs: `${version}plan/planSlabs`,
    getQuotationAnalysis:`${version}invoice/getQuotationAnalysis`,
    getLeadsAnalysis:`${version}admin/getLeadsAnalysis`,
    addBankDetails: `${version}invoice/addBankDetails`,
    addCompanyDetails: `${version}invoice/addCompanyDetails`,
    uploadInvoiceSignature:`${version}invoice/uploadInvoiceSignature`,
    getInvoiceAdditionalInfo:`${version}invoice/getInvoiceAdditionalInfo`
  };

  const baseUrl = getApiUrl();
  const routeEndPoint = Object.entries(routes).find((item) => item[0] === title);
  return `${baseUrl}${routeEndPoint[1]}`;
}

export function VBEndpoint(title) {
  const version = "v2/";
  const routes = {
    tokenGenerate: `${version}settings/token`,
  };

  const baseUrl = getVBApiUrl();
  const routeEndPoint = Object.entries(routes).find(
    (item) => item[0] === title
  );
  return `${baseUrl}${routeEndPoint[1]}`;
}

export const GOOGLE_AUTH_CLIENT_ID = "953073211267-0fgof26874r31npfsdfrjiku23pngarm.apps.googleusercontent.com";

export const URL_MAPPER = {
  // console: 'http://localhost:3000'
  console: "https://dev-console.sarv.com"
}
