import React, { useEffect, useState } from 'react';
import { Box, MenuItem, styled } from '@mui/material';

import CustomModal from 'src/components/modal/customModal';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { Stack } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PRODUCT_LIST } from 'src/config/commonConfig';
import Logger from 'src/lib/Logger';
import { axiosGetCall } from 'src/services/apisCall';
const logger = new Logger("create plan model");

const InputContainer = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '20px',
    flexDirection: 'row',
}));

const InputInnerContainer = styled('div')(() => ({
    width: 'calc(100% / 2)',
}));

const PlanForm = ({ open, onClose, plan, edit, handlePlanUpdate, handleAddPlan }) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const planSchema = Yup.object().shape({
        product_id: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Product Name is required'),
        plan_name: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Plan Name is required'),
        price: Yup.number().typeError('Price is invalid').required('Price is required'),
        min_price: Yup.number().typeError('Min Price is invalid'),
        max_price: Yup.number().typeError('Max Price is invalid'),
        min_user: Yup.number().typeError('Min User is invalid').required('Min User is required'),
        max_user: Yup.number().typeError('Max User is invalid').required('Max User is required'),
        currency: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Currency is required'),
        description: Yup.string()
            .transform((value) => (value ? value.trim() : ''))
            .required('Description is required'),
        duration_months: Yup.number()
            .positive()
            .integer()
            .typeError('Duration Months is invalid')
            .required('Duration Months is required'),
        cgst: Yup.number().positive().integer().typeError('CGST is invalid').required('CGST is required'),
        sgst: Yup.number().positive().integer().typeError('SGST is invalid').required('SGST is required'),
        yearly_discount: Yup.number()
            .positive()
            .integer()
            .typeError('Yearly Discount is invalid')
            .required('Yearly Discount is required'),
        monthly_discount: Yup.number()
            .positive()
            .integer()
            .typeError('Monthly Discount is invalid')
            .required('Monthly Discount is required'),
        features: Yup.array()
            .of(
                Yup.object().shape({
                    feature_name: Yup.string()
                        .transform((value) => (value ? value.trim() : ''))
                        .required('Feature Name is required'),
                    feature_description: Yup.string()
                        .transform((value) => (value ? value.trim() : ''))
                        .required('Feature Description is required'),
                })
            )
            .optional(),
        slabs: Yup.array().when('product_id', {
            is: 'Broadcast',
            then: () => {
                const voiceSchema = Yup.array()
                    .of(
                        Yup.object().shape({
                            min_calls: Yup.number().required('min_calls is required'),
                            max_calls: Yup.number()
                                .required('max_calls is required')
                                .moreThan(Yup.ref('min_calls'), 'max_calls must be greater than min_calls'),
                        })
                    )
                    .required('Slabs are required for Voice products')
                    .min(1, "At least one call range is required")
                    .test(
                        'min_calls-greater-than-previous-max_calls',
                        'min_calls must be greater than the previous max_calls',
                        function (array) {
                            if (!array || array.length < 2) {
                                return true;
                            }
                            for (let i = 1; i < array.length; i++) {
                                if (array[i].min_calls <= array[i - 1].max_calls) {
                                    return this.createError({
                                        path: `${this.path}[${i}].min_calls`,
                                        message: `Min Calls (${array[i].min_calls}) must be greater than the previous Max Calls (${array[i - 1].max_calls})`,
                                    });
                                }
                            }
                            return true;
                        }
                    );
                console.log('Voice schema:', voiceSchema.describe()); // Log the schema for debugging
                return voiceSchema;
            },
            otherwise: () => {
                const nonVoiceSchema = Yup.array().nullable();
                console.log('Non-Voice schema:', nonVoiceSchema.describe()); // Log the schema for debugging
                return nonVoiceSchema;
            },
        }),
    });

    const methods = useForm({
        resolver: yupResolver(planSchema),
        defaultValues: {
            slabs: [{ min_calls: '', max_calls: '', price: 0 }]
        }
        // defaultValues: plan,
    });

    const { control } = methods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'features',
    });

    const { fields: slabField, append: slabAppend, remove: slabRemove } = useFieldArray({
        control,
        name: "slabs",
    })

    const { setValue, handleSubmit, watch, reset } = methods;

    const values = watch();
    const onSubmit = async (data) => {
        edit ? await handlePlanUpdate(data, setLoadingButton) : await handleAddPlan(data, setLoadingButton);
    };

    const getPlanSlabs = async () => {
        try{
            const {slabs} = await axiosGetCall("slabs", {planId: plan?.plan_id});
            console.log("🚀 ~ getPlanSlabs ~ slabs:", slabs)
            setValue('slabs', slabs);
        } catch (error) {
            logger.error(error);
        }
    }
    useEffect(() => {
        if (plan) {
            setValue('product_id', plan.product_id);
            setValue('plan_id', plan.plan_id);
            setValue('plan_name', plan.plan_name);
            setValue('price', plan.price);
            setValue('min_price', plan.min_price);
            setValue('max_price', plan.max_price);
            setValue('min_user', plan.min_user);
            setValue('max_user', plan.max_user);
            setValue('currency', plan.currency);
            setValue('description', plan.description);
            setValue('duration_months', plan.duration_months);
            setValue('cgst', plan.cgst);
            setValue('sgst', plan.sgst);
            setValue('yearly_discount', plan.yearly_discount);
            setValue('monthly_discount', plan.monthly_discount);
            setValue('features', plan.features);
        }
        if(plan?.product_id === "Broadcast") {
            getPlanSlabs();
        }
    }, [plan, reset]);

    const description = (
        <Box>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack width={'100%'} direction={'column'} gap={2} sx={{ p: '25px', overflowY: 'auto', height: '700px' }}>
                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="plan_name" label="Plan Name" placeholder="Enter Plan Name." />
                        </InputInnerContainer>

                        <InputInnerContainer>
                            <RHFSelect
                                name="product_id" // The name of the field in the form
                                label="Select Product" // Label for the select dropdown
                                // helperText="Choose a product from the list"
                                control={control} // Pass the control object from useForm
                            >{
                                PRODUCT_LIST.map((ele) => (
                                <MenuItem value={ele}>{ele}</MenuItem>
                                ))
                            }
                            </RHFSelect>
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="price" label="Price" placeholder="Enter Price." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="currency" label="Currency" placeholder="Enter Currency." />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="min_price" label="Min Price" placeholder="Enter Min Price." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="max_price" label="Max Price" placeholder="Enter Max Price." type={'number'} />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="min_user" label="Min User" placeholder="Enter Min User." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="max_user" label="Max User" placeholder="Enter Max User." type={'number'} />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField name="sgst" label="SGST" placeholder="Enter SGST." type={'number'} />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField name="cgst" label="CGST" placeholder="Enter CGST." type={'number'} />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField
                                name="monthly_discount"
                                label="Monthly Discount"
                                placeholder="Enter Monthly Discount."
                                type={'number'}
                            />
                        </InputInnerContainer>
                        <InputInnerContainer>
                            <RHFTextField
                                name="yearly_discount"
                                label="Yearly Discount"
                                placeholder="Enter Yearly Discount."
                                type={'number'}
                            />
                        </InputInnerContainer>
                    </InputContainer>

                    <InputContainer>
                        <InputInnerContainer>
                            <RHFTextField
                                name="description"
                                label="Description"
                                placeholder="Enter Description."
                                type={'text'}
                                multiline
                                rows={4}
                            />
                        </InputInnerContainer>

                        <InputInnerContainer>
                            <RHFTextField
                                name="duration_months"
                                label="Duration Months"
                                placeholder="Enter Duration Months."
                                type={'number'}
                            />
                        </InputInnerContainer>
                    </InputContainer>

                    {values?.product_id === "Broadcast" && <InputContainer>
                        <Typography variant="h6">Slabs</Typography>
                        <Button onClick={() => slabAppend({ min_calls: '', max_calls: '', price: 0 })}>Add Slabs</Button>
                    </InputContainer>}
                    {values?.product_id === "Broadcast" && slabField.map((item, index) => (
                        <Box key={item.id} display="flex" alignItems="center" gap={2}>
                            <InputInnerContainer>
                                <Controller
                                    name={`slabs[${index}].min_calls`}
                                    control={control}
                                    defaultValue={item.min_calls || ''}
                                    render={({ field }) => (
                                        <RHFTextField {...field} label="Minimum Credits" placeholder="Enter Minium." />
                                    )}
                                />
                            </InputInnerContainer>
                            <InputInnerContainer>
                                <Controller
                                    name={`slabs[${index}].max_calls`}
                                    control={control}
                                    defaultValue={item.max_calls || ''}
                                    render={({ field }) => (
                                        <RHFTextField
                                            {...field}
                                            label="Max Credits"
                                            placeholder="Enter Max Credits."
                                        // multiline
                                        // rows={3}
                                        />
                                    )}
                                />
                            </InputInnerContainer>
                            <InputInnerContainer>
                                <Controller
                                    name={`slabs[${index}].price`}
                                    control={control}
                                    defaultValue={item.price || 0}
                                    render={({ field }) => (
                                        <RHFTextField
                                            {...field}
                                            type={"number"}
                                            label="Price"
                                            placeholder="Enter Price"
                                        // multiline
                                        // rows={3}
                                        />
                                    )}
                                />
                            </InputInnerContainer>
                            {index > 0 && <IconButton onClick={() => slabRemove(index)} color="error">
                                <DeleteIcon />
                            </IconButton>}
                        </Box>
                    ))}

                    <InputContainer>
                        <Typography variant="h6">Features</Typography>
                        <Button onClick={() => append({ feature_name: '', feature_description: '' })}>Add Feature</Button>
                    </InputContainer>

                    {fields.map((item, index) => (
                        <Box key={item.id} display="flex" alignItems="center" gap={2}>
                            <InputInnerContainer>
                                <Controller
                                    name={`features[${index}].feature_name`}
                                    control={control}
                                    defaultValue={item.feature_name || ''}
                                    render={({ field }) => (
                                        <RHFTextField {...field} label="Feature Name" placeholder="Enter Feature Name." />
                                    )}
                                />
                            </InputInnerContainer>
                            <InputInnerContainer>
                                <Controller
                                    name={`features[${index}].feature_description`}
                                    control={control}
                                    defaultValue={item.feature_description || ''}
                                    render={({ field }) => (
                                        <RHFTextField
                                            {...field}
                                            label="Feature Description"
                                            placeholder="Enter Feature Description."
                                        // multiline
                                        // rows={3}
                                        />
                                    )}
                                />
                            </InputInnerContainer>
                            <IconButton onClick={() => remove(index)} color="error">
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}

                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                        <LoadingButton type="submit" variant="contained" loading={loadingButton} sx={{ width: '120px' }}>
                            Submit
                        </LoadingButton>
                    </Stack>
                </Stack>
            </FormProvider>
        </Box>
    );

    return <CustomModal open={open} title="Create Plan" description={description} close={onClose} width={'60%'} />;
};
export default PlanForm;
