import React from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

import pageTitle from "../utils/pageTitles.json";
import { BlankCard } from '../components/cards/blankCard';
import { IncompleteKycTable } from '../sections/kyc/IncompleteKyc';
import KycTitleIcon from '../assets/svgs/kycTitleIcon';

// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 0px 88px",
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: 'calc(100vh - 0px)',
    overflow: 'hidden'
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
}));

const DetailsListSection = styled('div')(() => ({
    width: '100%',
    height: 'calc(100vh - 155px)',//'554px',
    overflow: 'hidden',
    overflowY: "scroll",
}));

const kycUserList = (
    <Box sx={{ width: '100%' }}>
        <DetailsListSection className='reportListScroll'>
            <IncompleteKycTable />
        </DetailsListSection>
    </Box>
)


const IncompleteKYC = () => {

    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.kyc}`} </title>
            </Helmet>

            <MainSection>

                {/* Heading and filters */}
                <HeadingSection>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            widht: '16px',
                            height: '16px',
                            mt: -1.8
                        }}>
                            <KycTitleIcon />
                        </Box>
                        <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                            KYC
                        </Typography>
                    </Box>
                </HeadingSection>

                <BlankCard
                    key="1"
                    sx={{
                        height: '100%',
                        width: '100%',
                        padding: '0px',
                        overflow: 'hidden',
                        // borderTopLeftRadius: '0px',
                        // borderTopRightRadius: '0px'
                    }}
                >
                    {kycUserList}
                </BlankCard>

            </MainSection>

        </>
    )
}

export default IncompleteKYC;
