import React, { useState, useRef } from 'react';

import { styled } from '@mui/material/styles';
import FileUploader from './fileUploader';
import { Button, Box, TextField, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import { axiosPostCall } from '../../services/apisCall';

const MainSection = styled('div')(() => ({
    width: '450px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column'
}));

const CustomTextField = styled(TextField)(() => ({
    backgroundColor: "#D0D0D1",
    borderColor: "#5B5C5D",
    borderRadius: '6px',
    '& input': {
        color: "#5B5C5D",
    },
    width: '100%',
    height: 'auto'
}));

const FormSection = () => {
    const dasktopImgRef = useRef();
    const mobileImgRef = useRef();

    const [dasktopImg, setDasktopImg] = useState("");
    const [mobileImg, setMobileImg] = useState("");
    const [url, setUrl] = useState("");

    const publishAd = async(e) => {
        e.preventDefault();
        try {
            if(!url || !mobileImg || !dasktopImg) return renderErrorMessage("Please upload all images and data!")
            const formData = new FormData();
            formData.set('mobile', mobileImg);
            formData.set('desktop', dasktopImg);
            formData.append('redirectPath', url);

            const { message } = await axiosPostCall("advertisement", formData);
            renderSuccessMessage(message);
        } catch (error) {
            console.error("kyc.jsx , handleSubmit", error)
            renderErrorMessage(error.message);
        }
    }
    return (
        <MainSection>
            {/* <ToastContainer /> */}

            <FileUploader
                inputName="Upload desktop size image"
                inputId="electrucityBill"
                inputRef={dasktopImgRef}
                setFile={setDasktopImg}
            />
            <FileUploader
                inputName="Upload mobile size image"
                inputId="electrucityBill"
                inputRef={mobileImgRef}
                setFile={setMobileImg}
            />

            <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'column',
                width: '100%'
            }}>
                <Typography sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: "#898A8B"
                }}>
                    Advertisement page url (i.e https://offer.sarv.com)
                </Typography>

                <CustomTextField
                    type="text"
                    id="url"
                    onChange={(e) => setUrl(e.target.value)}
                    value={url}
                    size='small'
                    placeholder="Please enter url"
                    variant="outlined"
                />

            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                mt: '10px'
            }}>

                <Button
                    variant='contained'
                    sx={{
                        width: '100px',
                        boxShadow: 'none'
                    }}
                    onClick={(e) => publishAd(e)}
                >
                    Publish Ad
                </Button>
            </Box>

        </MainSection>
    )
}

export default FormSection