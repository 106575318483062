import React, { useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { renderErrorMessage, renderSuccessMessage } from 'src/lib/toastMessage';
import { ToastContainer } from 'react-toastify';
import { axiosPostCall } from '../../services/apisCall';
import FileUploader from '../advertisement/fileUploader';

export default function FileUploadDialog({ openDialog, handleClose, data, setOpenDialogUpload }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const invoiceRef = useRef();

  const handleUpload = async () => {
    try {
      setLoadingState(true)
      if (!selectedFile) return renderErrorMessage("Please upload tax invoice pdf!")
      data.action = "finalInvoice";
      const formData = new FormData();
      formData.set('taxInvoice', selectedFile);
      formData.set('data', JSON.stringify(data));
      const { message } = await axiosPostCall("sendTaxInvoice", formData);
      if (message === "Success") {
        setLoadingState(false);
        setOpenDialogUpload(false);
        renderSuccessMessage(message);
        setSelectedFile(null)
      } else {
        renderErrorMessage("Error in uploading!")
        setSelectedFile(null);
        setLoadingState(false)
      }
    } catch (error) {
      console.error("error in upload tax invoice", error)
      renderErrorMessage(error.message);
      setLoadingState(false)
    }
  }

  return (
    <>
      {/* <ToastContainer /> */}

      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Upload a File</DialogTitle>
        <DialogContent>
          <FileUploader inputName="Select file"
            inputId="taxInvoice"
            inputRef={invoiceRef}
            setFile={setSelectedFile}
          />
        </DialogContent>
        <DialogActions sx={{marginTop:'20px'}}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="success" variant="contained">
            {loadingState ? <><CircularProgress size={17} sx={{ color: "error", marginRight: '8px' }} /> Uploading... </> : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
